.summary {
  font-size: 14px;
  margin-bottom: 0;
  padding: 0 var(--small) var(--small) var(--small);
}

.print {
  display: none;
}

@media print {
  .print {
    display: inline;
  }
}