.salaryMarker {
  display: flex;
  margin: 0 0 var(--xsmall) auto;
  white-space: nowrap;
}

.salaryMarker .input {
  margin: 0 var(--small);
  width: 90px;
}

.infoLabelWrapper {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-around;
}

.information {
  border-bottom: 1px dashed var(--primary-color-dark);
  color: var(--primary-color-dark);
  font-size: 14px;
}

.label {
  font-weight: bold;
}

.icon {
  padding-top: 15px;
}

.tooltip {
  visibility: hidden;
  width: 250px;
  background-color: var(--bg-grey-darker);
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 15px;
  white-space: pre-wrap;
  box-shadow: 2px 2px 10px 1px rgba(0,0,0,0.3);

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 105%;
  left: 50%;
  margin-left: -200px;
}

.tooltipText {
  font-size: 16px;
}

.information:hover .tooltip {
  visibility: visible;
}
