.logItem {
  cursor: pointer;
  background-color: rgb(27, 27, 27);
  color: white;
  padding: var(--small);
  font-size: 11pt;
  align-items: center;
}

.logRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logChevron {
  margin-right: 15px;
}

.logItem span {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.logItem p {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: black;
}

.extraInfo button {
  float: right;
  margin-top: -10px;
}

.extraInfo p {
  margin-left: var(--small);
  margin-right: var(--small);
}

.copyButton {
  background-color: unset;
  border: 1px solid white;
  border-radius: 10px;
  color: white;
  margin-left: auto;
}

.Information {
  border-bottom: 1px dashed #c5c5c5;
  background-color: #368358;
}

.Warning {
  border-bottom: 1px dashed #c5c5c5;
  background-color: #BC5806;
}

.Error {
  border-bottom: 1px dashed #c5c5c5;
  background-color: #de4d2f;
}

.timeStamp {
  font-weight: bold;
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
}