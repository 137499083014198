.policyWrapper {
  background-color: white;
}

.policyInnerWrapper {
  margin: 0 auto;
  max-width: var(--inner-container-max-width);
  padding: var(--small) var(--medium);
}

.policySection {
  margin: 0 auto;
  max-width: var(--inner-container-max-width);
  padding: 1px var(--medium) var(--large);
}
