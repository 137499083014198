.accordionWrapper {
  background-color: white;
  box-sizing: border-box;
  border: 0;
  padding: 0;
  margin: 0;
}

.accordionWrapper.isStacked .heading {
  border-top: 1px dashed var(--border-color);
}

.accordion {
  border: 0;
  padding: 0;
  margin: 0;
}

.heading {
  margin: 0;
  font-weight: 100;
  box-sizing: border-box;
  padding-inline-end: 0;
  padding-inline-start: 0;
  width: 100%;
  background-color: white;
}

.heading.open {
  border-bottom: 1px dashed var(--border-color);
}

.heading.disabled span {
  color: var(--text-color-disabled);
}

.heading button {
  font-size: 16px;
  padding: var(--large);
}

.accordionWrapper.isSlim .heading button {
  padding: var(--medium) var(--small);
}

.accordionWrapper.hasBorder {
  border: 1px solid var(--border-color);
}

.accordionWrapper.hasBoxShadow {
  box-shadow: var(--box-shadow);
}

.accordionWrapper.hasMarginBottom{
  margin-bottom: var(--medium);
}

.heading svg {
  margin-left: auto;
  min-width: 20px;
}

.accordionContent {
  display: none;
  margin: 0;
  padding: var(--large);
}

.accordionWrapper.isSlim .accordionContent {
  padding: 0;
}

.accordionContent.open {
  display: block;
}

.explanationItem {
  border-bottom: var(--border-dashed-thick);
  padding: var(--medium);
}

.accordionWrapper.isSlim .explanationItem {
  padding: var(--medium) var(--small);
}

.explanationItem p {
  margin: 0;
}

.explanationItem a {
  border-bottom: 1px dashed var(--primary-color-dark);
  color: var(--primary-color-dark);
  display: inline-block;
  font-size: 12px;
  padding: 5px 0px;
}

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
