.main {
  max-width: var(--outer-container-max-width);
  margin: 0 auto;
}

.topNav {
  display: flex;
  border-bottom: 1px dashed var(--border-color);
  padding: var(--small) 0;
  margin-bottom: var(--medium);
}

.innerContainerSlim {
  margin: 0 auto;
  padding: var(--xxxlarge) 0;
  max-width: var(--inner-container-max-width);
  padding: var(--large) var(--medium);
}

.developerContainer {
  max-width: var(--inner-container-max-width);
  margin: 0 auto;
}

.developerContainer > h1 {
  font-size: 2rem;
}

.developerContainer .preamble {
  font-weight: var(--font-weight-bold);
}

.flowchartSymbolContainer {
  margin: var(--xxxxlarge) 0;
}

.flowchartSymbolContainer img {
  position: relative;
  width: 100%;
}

.developerContainer .noticeBox {
  border-radius: var(--border-radius);
  border: var(--border);
  padding: var(--large);
  background-color: aliceblue;
  margin-top: var(--large);
  overflow-wrap: break-word;
}

.developerContainer .noticeBox > span {
  position: relative;
  margin-right: var(--small);
}

.developerContainer .noticeBox > span > svg {
  position: relative;
  top: var(--xsmall);
}

.developerContainer .noticeBox > a {
  color: var(--primary-color-dark);
  font-size: var(--large);
}

.developerContainer .box.attentionBox {
  background-color: burlywood;
}

.noMarginTopBottom {
  margin-top: 0;
  margin-bottom: 0;
}
