.main {
  display: flex;
  flex-direction: column;
  border-bottom: 1px dashed black;
}

.textContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.examples {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.examples > div {
  margin-left: 10px;
  margin-right: 10px;
}

.examples > p {
  margin-right: 20px;
}

.percentileText {
  font-size: 15pt;
}

.salaryLineOuter {
  display: flex;
  flex-direction: row;
}

.salaryLineInner {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
}

.lineFiller {
  content: '';
  display: block;
  background: rgb(185, 222, 228);
  width: 7%;
  height: 60px;
}

.midLine {
  position: relative;
  content: '';
  height: 60px;
  width: 2px;
  background-color: black;
}

.percentileBound {
  content: '';
  position: relative;
  height: 130%;
  border-right: 2px dashed black;
}

.midLine::after {
  content: '';
  margin-top: 19px;
  margin-left: -11px;
  display: inline-block;
  background-color: var(--secondary-color-lighter);
  transform: rotate(45deg);
  height: 20px;
  width: 20px;
  border: 2px solid black;
}

.yourSalary::before {
  display: inline-block;
  content: '';
  height: 60px;
  width: 2px;
  background-color: black;
  margin-right: -10px;
}

.yourSalary {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-left: -4px;
}

.yourSalaryCircle {
  border-radius: 50%;
  position: relative;
  background-color: white;
  height: 24px;
  width: 24px;
  border: 2px solid black;
  margin-left: -5px;
}

.yourSalaryCircle svg {
  width: 22px;
  height: 20px;
  left: 1px;
  top: 1px;
  position: relative;
}

.midLineExplain {
  margin-left: -5px;
  display: inline-block;
  background-color: var(--secondary-color-lighter);
  transform: rotate(45deg);
  height: 18px;
  width: 18px;
  min-width: 18px;
  border: 2px solid black;
}
