.presentation {
  margin: var(--medium) 0;
}


@media (min-width: 768px) {
  .presentation {
    margin: 0 0 0 var(--small);
  }
}
