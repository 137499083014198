.tab {
  align-self: flex-end;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin: 0;
  padding: var(--small) var(--medium);
}

.tab:focus:not(:focus-visible) {
  outline: none;
}

.active {
  background-color: transparent;
  box-shadow: 1px 0 0 0 var(--border-color), 0 1px 0 0 #ffffff,
    1px 1px 0 0 var(--border-color), 1px 0 0 0 var(--border-color) inset,
    0 1px 0 0 var(--border-color) inset;
  color: var(--text-color);
  pointer-events: none;
}

.passive {
  background-color: #fafafa;
  box-shadow: 1px 0 0 0 var(--border-color), 0 1px 0 0 var(--border-color),
    1px 1px 0 0 var(--border-color), 1px 0 0 0 var(--border-color) inset,
    0 1px 0 0 var(--border-color) inset;
  color: var(--primary-color-dark);
}

.passive:hover {
  background-color: var(--bg-grey-darker);
}

@media print {
  .tab {
    box-shadow: none;
  }
  .passive {
    display: none;
  }
}