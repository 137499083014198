.sectionElement {
  padding: 0;
  padding-bottom: var(--xxlarge);
}

.sectionElement.isDashed {
  background-color: white;
  border-radius: var(--border-radius);
  border: var(--border-dashed-thick);
  padding: var(--large);
}

.sectionElement.isDashed .sectionHeading {
  margin-top: 0;
  padding-top: 0;
}

