.defaultText {
  font-style: italic;
}

.optionRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: var(--large);
}

.optionRow:focus:not(:focus-visible) {
  outline: none;
}

.optionsList {
  display: flex;
  flex-direction: column;
  padding: var(--medium);
  line-height: 22px;
}

.optionsList p {
  margin: 5px 0;
}

.optionsList .subHeader {
  display: block;
  font-weight: bold;
}

.itemHeading {
  font-weight: bold;
}
