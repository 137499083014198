.situationCard {
  box-sizing: border-box;
  border: var(--border);
  padding: var(--medium);
  background-color: white;
  border-radius: var(--border-radius-subtle);
  margin-bottom: var(--medium);
}

.situationCardInner {
  display: grid;
  grid-template-columns: 60% auto;
  grid-gap: var(--medium);
}

.situationDescription {
  height: 140px;
}