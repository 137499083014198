.container {
  min-height: 280px;
  position: relative;
}

.isLoading {
  background-color: rgba(255, 255, 255, .8);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 20;
}