.disableMenuselection {
  background-color: lightgray;
}

.showAllFilterWrapper {
  padding: 0 var(--small) var(--small) var(--small);
}

.showAllFilters {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: var(--medium);
  width: 100%;
  background-color: var(--bg-grey);
  border-bottom: 1px dashed var(--border-color);
  border-top: 1px dashed var(--border-color);
}

.showAllFilters:hover {
  background-color: var(--bg-grey-darker);
}

@media (min-width: 768px) {
  .filterScrollArea {
    overflow-y: auto;
    max-height: var(--filter-section-max-height-desktop);
  }
}

@media (max-width: 768px) {
  .mobileEmpty {
    display: none;
  }
}

.filterListPadding {
  padding: 0 var(--small);
}
