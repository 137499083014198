.buttonActiveFilter {
  background-color: transparent;
  color: var(--primary-color-dark);
  cursor: pointer;
  border: 1px dashed var(--border-color);
  padding-bottom: 2px;
  padding-right: 2px;
  box-sizing: border-box;
}

.buttonActiveFilter .buttonIcon {
  position: relative;
  top: 3px;
  margin-left: 2px;
}

.buttonActiveFilter .buttonText {
  font-size: 14px;
  margin-right: 1px;
  display: inline-block;
}

.buttonActiveFilter.isLarge .buttonText {
  font-size: 20px;
}

.buttonActiveFilter.isLarge .buttonIcon {
  top: 4px;
}

.buttonActiveFilter:hover {
  background-color: var(--bg-grey-darker);
}

.buttonActiveFilter:disabled {
  color: var(--text-color-disabled);
  pointer-events: none;
}
