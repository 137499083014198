.downloadSection {
  position: relative;
}

.downloadOptions {
  position: absolute;
  top: var(--xxxxlarge);
  width: 210px;
  background-color: #ffffff;
  right: 0;
  border: 1px solid var(--border-color);
  box-shadow: var(--box-shadow);
  z-index: 20;
}

.optionBtn {
  color: var(--primary-color-dark);
  font-size: 16px;
  padding: var(--large);
}

.optionBtn:hover,
.optionBtn:focus {
  text-decoration: underline;
}

