.menuButtonContainer {
  justify-self: self-end;
  align-self: center;
  position: relative;
}

@media print {
  .menuButton {
    display: none;
  }
}

.menuOptions {
  background-color: #ffffff;
  border: 1px solid var(--border-color);
  box-shadow: var(--box-shadow);
  position: absolute;
  right: 0;
  top: var(--xxlarge);
  width: 210px;
  z-index: 20;
}

.optionBtn {
  background-color: #ffffff;
  box-sizing: border-box;
  color: var(--primary-color-dark);
  font-size: 16px;
  padding: var(--large);
}

.optionBtn:hover {
  text-decoration: underline;
}
