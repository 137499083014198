.main {
  flex-shrink: 0;
  width: 100%;
}

footer h2 {
  margin-top: 0;
}

.innerContainerFull {
  margin: 0 auto;
  padding: var(--xxxlarge) 0;
  max-width: var(--outer-container-max-width);
  padding: var(--large) var(--medium);
  display: flex;
}

@media (max-width: 768px) {
  footer .innerContainerFull {
    display: block;
  }
  footer .innerContainerFull > div:first-child {
    margin-bottom: var(--xxxlarge);
  }
}

footer .innerContainerFull > div {
  flex-grow: 2;
  flex-basis: 0;
}

footer .innerContainerFull > div:first-child {
  padding-right: var(--large);
}

.mainSection {
  background-color: #fff;
  border-top: var(--border);
  padding: var(--xxxlarge) 0;
}

.subSection {
  max-width: var(--outer-container-max-width);
  margin: 0 auto;
}

.subSectionContent {
  height: 100%;
  display: flex;
  justify-content: center;
}

.logo {
  min-width: 175px;
  max-height: 100px;
}

footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

footer ul li {
  margin-top: var(--medium);
}

footer .sacoLink {
  color: var(--primary-color-dark);
  font-size: 18px;
  border-bottom: 1px dashed var(--primary-color-dark);
}

footer .sacoLink:hover {
  border-bottom: 1px solid var(--primary-color-dark);
}

@media print {
  footer {
    display: none;
  }
}
