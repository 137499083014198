.configTable {
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.configTable td, .configTable th {
  padding: var(--small);
}

.configTable tr:nth-child(odd), 
.configTable th {
  background-color: #fafafa;
}

.configTable th {
  text-align: left;
  box-shadow: inset 0px -1px 0px #707070;
}

.configTable th.center {
  text-align: center;
}

.configTable tr {
  box-shadow: inset 0px -1px 0px var(--border-color);
}

.configTable tr:nth-child(even) {
  background-color: #ffffff;
}

.configTable .radioLabel {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.configTable .radioLabel, 
.configTable .radioLabel input {
  cursor: pointer;
}

.configTable .radioLabel.disabled, 
.configTable .radioLabel.disabled input {
  pointer-events: none;
}

.configItem {
  border-radius: 5px;
  border: 2px dashed var(--border-color);
  display: grid;
  grid-template-columns: auto 20px;
  margin-right: var(--medium);
  padding: var(--small);
  width: 100%;
}

.configTable button {
  margin-right: var(--xsmall);
}

.configTable .optionsList {
  display: flex;
  flex-direction: column;
  padding: var(--small);
  line-height: 22px;
}
