.loaderAnimation {
  width: 100%;
  display: flex;
  justify-content: center;
}

.filter {
  margin-bottom: var(--large);
}

.filter div {
  max-width: 400px;
}

.accountTable {
  margin-bottom: var(--large);
  overflow: auto;
}

.accountTable .table {
  width: 100%;
}

.accountTable th,
.accountTable td {
  border-left: 1px solid var(--border-color);
}

.accountTable th:last-child,
.accountTable td:last-child {
  border-right: 1px solid var(--border-color);
}

.table {
  display: table;
  white-space: nowrap;
  background-color: #f1f1f1;
  text-align: center;
  margin-bottom: var(--medium);
  text-align: start;
  border-collapse: collapse;
  width: 100%;
  align-self: flex-start;
}

.table th,
.table td {
  padding: var(--small);
  text-align: left;
  font-size: 14px;
}

.table th {
  border-bottom: 1px solid #707070;
  background-color: #f1f1f1;
}

.table tr {
  border-bottom: 1px solid var(--border-color);
}

.table tr:nth-child(odd) {
  background-color: #fafafa;
}

.table tr:nth-child(even) {
  background-color: #f1f1f1;
}
