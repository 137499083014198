.sourceSelectContainer {
  display: block;
  margin-bottom: var(--large);
}

.sourceSelectContainer label {
  display: block;
}

.tabRow {
  margin-bottom: var(--medium);
}

.loaderContainer {
  text-align: center;
}

.shown {
  display: block;
}

.hidden {
  display: none;
}