body {
  background-color: white;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 650px;
}
