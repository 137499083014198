.customPresentation {
  display: block;
  margin-bottom: var(--large);
  background-color: white;
  border: var(--border);
}

.customPresentation h4 {
  padding: var(--medium);
  padding-bottom: 0;
}

.customPresFlex {
  display: flex;
  align-self: start;
}

.customPresFlex > div {
  display: inline-block;
  flex-grow: 1;
  padding: var(--medium);
  padding-top: 0;
}

@media (max-width: 768px) {
  .customPresFlex {
    display: block;
  }

  .customPresFlex > div {
    display: block;
  }
}

.customPresentationHeading {
  margin: 0 0 var(--small) 0;
}

.customPresentationSelection {
  margin-right: var(--small);
}

@media print {
  .customPresentation {
    display: none;
  }
}
