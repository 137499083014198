.main {
  background-color: white;
  max-width: var(--inner-container-max-width);
  margin: 0 auto;
}

.innerContainerFull {
  margin: 0 auto;
  padding: var(--xxxlarge) 0;
  max-width: var(--outer-container-max-width);
  padding: var(--large) var(--medium);
}

.main > h1 {
  margin: 0;
}

.main > p {
  margin: 0;
  margin-top: var(--large);
  line-height: 1.5;
  font-size: 18px;
}

.banner {
  border-top: var(--border-dashed-thick);
  max-width: var(--outer-container-max-width);
  margin: 0 auto;
  padding: var(--xxxlarge) 0 var(--large) 0;
}

@media (max-width: 768px) {
  .main {
    margin: 0;
  }
  .banner {
    margin: 0 var(--medium);
  }
}
