.logTools {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: var(--small);
  margin-right: var(--small);
  align-items: flex-end;
}

.loaderAnimation {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 75vh;
}

.logPanel {
  background-color: rgb(33, 33, 33);
  padding: 0;
  height: 75vh;
  overflow-y: scroll;
  border: 1px solid var(--border-color);
  box-sizing: border-box;
}

.errorText {
  color: white;
}

.panelFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--medium);
}

.pageIndicator {
  display: inline-block;
  margin-top: var(--small);
}