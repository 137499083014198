@font-face {
  font-family: 'Proxima Nova';
  src: url('/preview/ProximaNova-Regular.woff') format('opentype');
}

@font-face {
font-family: 'Passenger-Display';
src: url('/preview/PassengerDisplay-Regular-BF63f2d24127b50.otf') format('opentype');
}

@font-face {
font-family: 'ProximaNova-SemiBold';
src: url('/preview/ProximaNova-Semibold.woff') format('opentype');
}

@font-face {
font-family: 'Proxima Nova';
src: url('/preview/ProximaNova-Regular.woff') format('opentype');
}

@font-face {
font-family: 'ProximaNova-Bold';
src: url('/preview/ProximaNova-Bold.woff') format('opentype');
}

@font-face {
font-family: 'PassengerDisplay-Extrabold';
src: url('/preview/PassengerDisplay-Extrabold.otf') format('opentype');
}


.unemploymentBarChart{
margin-left: -28px;
}

@media (min-width: 600px) {
.unemploymentBarChart{
  margin-right: -22px;
}
}

.UnemploymentspinContainer{
  background-color: #ffffff;  
}

.UnemploymentspinHeader{
height: 60px;
}

.icon {
width: 24px; /* Justera bredden efter behov */
height: 24px; /* Justera höjden efter behov */
margin-right: 10px; /* Justera höjden efter behov */
margin-bottom: 3px;
}

.PT24px{
  padding-top: 24px;
}

.UnemploymentspinLogo{
  width: 170px;
  padding: 20px;
  padding-right: 40px;
  padding-top: 40px;
  padding-left: 31px;
}  


@media (max-width: 600px) {
.UnemploymentspinLogo{
  visibility: hidden;
  padding: 0px;
  width: 4px;

}
}

.UnemploymentspinHeader h2{
font-family: 'ProximaNova-SemiBold';
font-size: 24px;
color: #ffffff;
padding: 0px 2px;
}
.UnemploymentspinSummary {
height: 310px;
margin-bottom: 20px;
background-color: #F7F7F7;
position: relative;
}

@media (max-width: 600px) {
.UnemploymentspinSummary {
  height: 460px;
}
}


.UnemploymentspinSummary h1{
color:#008EA1;
font-size: 36px;
margin-top: 10px;
margin-bottom: 10px;
font-family: 'ProximaNova-Bold';
}

.UnemploymentspinSummary p{
font-family: 'Proxima Nova';
}

.UnemploymentspinArrow{
position: absolute;
top: 209px;
right: 20px;
}

@media (max-width: 600px) {
.UnemploymentspinArrow{
  top: 365px;
} 
}
.UnemploymentspinArrow span{
font-size: 14px;
font-family: 'Proxima Nova';
cursor: pointer;
}
.UnemploymentspinArrow img{
margin-bottom: -9px;
cursor: pointer;
}

.Baseline{
display: flex;
position: relative;
}

@media (max-width: 600px) {
.Baseline{
  padding: 15px;
}
}

@media (min-width: 600px) {
.UnemploymentspinAbout{
  display: flex;
 }
}

.FirstParagraph{
font-size: 18px;
font-weight: 600;
margin: 0px;
margin-bottom: -18px;

}

.SecondParagraph{
font-size: 96px;
font-weight: 800;
font-family: 'PassengerDisplay-Extrabold' !important;
color:#C13D8C;
margin: 0px;
white-space: nowrap; /* Prevents text from wrapping to a new line */
flex: none; /* Takes up only as much space as needed */
}

@media (max-width: 600px) {
.SecondParagraph{
  margin-top: -10px;
  margin-bottom: -10px;
}
}

.ThirdParagraph{
font-size: 14px;
margin-bottom: 0px;
padding-top: 0px;
}

@media (min-width: 600px) {
  .ThirdParagraph{
    margin-left: 20px;
    padding-top: 13px;
    width: 44%;
  }
}

@media (max-width: 600px) {
.ThirdParagraph{
  margin-top: 0px;
}
}

.UnemploymentspinHeader{
background-color: #006371;
border-top-left-radius: 6px;
border-top-right-radius: 6px;

display: flex;
align-items: center;
padding-left: 20px;
padding-right: 20px;

}

.regularPanel{
  margin: 0px;
  padding: 0px;
}  

.UnemploymentspinContainer{
  margin: 4px;
}

@media (min-width: 600px) {
  .UnemploymentspinContainer{
    max-width: 980px !important;
    height: 1690px;
  }
}


.UnemploymentspinMain{
  padding: 10px 30px;
  background-color: #F7F7F7;
}


.UnemploymentspinSacoGreen{
  padding-left: 30px;
}

@media (max-width: 600px) {
  .UnemploymentspinMain{
    padding: 5px 15px;
  }

  .UnemploymentspinSacoGreen{
    padding-left: 15px;
  }
}


.UnemploymentspinMain p{
  font-family: 'Proxima Nova';
}

.UnemploymentspinTabRow{
  margin-top: 20px;
  background-color: white;
  color: #ffffff !important;
}

.textContent{
  max-width: 578px;
  margin-bottom: 20px;
}

@media (min-width: 600px) {
.textContent{
  max-height: 100px;
}
}

.UnemploymentspinMain h1,
.UnemploymentspinSacoGreen{
  color: #058FA2;
  font-size: 24px;
  font-family: 'ProximaNova-Bold' !important;
}

.tableHead button {
  background-color: #005561;
  border: #058FA2;
  Width: auto;
  Height: 19px;
  font-size: 12px;
  text-align: center;
  margin-left: 0px;
  font-family: 'ProximaNova-SemiBold';
  color: #ffffff;
  margin: 0;
}

.activeButton {
  background-color: #058FA2 !important; 

  color: white;
}

.tableHead {
  display: flex;
  align-items: center; /* Centrera elementen vertikalt */
  justify-content: space-between; /* Placera elementen längst ut på varje sida */
  padding-top: 20px;
}

.tableHead h1 {
  margin: 0; /* Ta bort standardmarginalen från h1 */
}

.tableHead .buttonsContainer {
  display: flex;
}

.UnemploymentspinContainer span{
  font-size: 12px;
}

/*Tabellen*/

.tableContent {
  text-align: left;
  border: 2px solid transparent;
  margin-bottom: 20px;
}

@media (min-width: 600px) {
  .tableContent {
    width: 573px;
  }
}

/* Exempel på CSS för att visa bara de vertygiga och horisontella strecken i tabellen */
.tableContent table {
border-collapse: collapse;
width: 100%;
}

.tableContent th, 
.tableContent td {
padding: 8px;
text-align: left;
font-size: 14px;
font-family: 'Proxima Nova';
}

.tableContent th{
  font-family: 'ProximaNova-SemiBold';
}

.tableContent td,
.tableContent th
{
border-left: none;
border-right: none;
padding-left: 0px;
}
.tableContent tr
{
border-top: none;
}

.tableContent tr:first-child th {
border-top: none;
}

.UnemploymentspinSource{
font-size: 12px;
font-weight: 300;
}

.UnemploymentspinDownload{
margin-top: 30px;
display: flex;

}
.UnemploymentspinDownload p{
margin-left: 20px;
font-size: 18px;
font-weight: 700;
}

