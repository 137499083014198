.pageAdmin {
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-gap: 15px;
  width: var(--outer-container-max-width);
  margin: 0 auto;
  padding: 0 var(--medium);
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
}

.adminH1 {
  margin-top: 10px;
}

.forbundSelectWrapper {
  margin-bottom: var(--medium);
}

.mainContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  overflow: auto;
}

.navHeader {
  font-size: 18px;
  margin-top: 0;
}

.toolBar {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--large);
}

.navButton {
  cursor: pointer;
  background-color: white;
  color: var(--dark-blue);
  border: none;
  padding: 12px 12px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  box-shadow: var(--box-shadow);
  border-bottom: 2px solid var(--dark-blue);
  outline: none;
  margin-bottom: var(--small);
}

.toolBar > button + button {
  margin-top: 10px;
}

.adminHeading {
  background-color: lightsteelblue;
  padding: var(--large);
}
