.selectionCard {
  background-color: white;
  border-bottom: var(--xsmall) solid var(--primary-color);
  border-radius: var(--border-radius-subtle);
  border: var(--border);
}

.selectionCard label {
  align-items: center;
  color: var(--text-color);
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  padding-top: var(--xxlarge);
  padding-bottom: var(--xxlarge);
  box-shadow: var(--box-shadow);
  width: 100%;
}

@media (max-width: 768px) {
  .selectionCard label {
    padding-top: var(--medium);
    padding-bottom: var(--medium);
  }
}

.selectionCard label.loading,
.selectionCard label.disabled {
  pointer-events: none;
  cursor: default;
}

.selectionCard label.disabled.selected {
  box-shadow: none;
  pointer-events: none;
  cursor: default;
}

.selectionCard + .selectionCard {
  margin-top: var(--medium);
}

.selectionCard .inputContainer {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  padding-left: var(--xxlarge);
  padding-right: var(--xxlarge);
  width: 24px;
}

.selectionCard label:hover > div input[type='radio']:not(:checked) + span > span {
  background-color: var(--checkbox-radio-bg-hover);
  border: 2px solid white;
  border-radius: 100%;
}

@media (max-width: 768px) {
  .selectionCard .inputContainer {
    padding-left: var(--medium);
    padding-right: var(--medium);
  }

  .selectionCard label:hover > div input[type='radio']:not(:checked) + span > span {
    background-color: transparent;
  }
}

/* TODO right now the selected state also triggers disabled state, so if you style disabled it will affect selected.. */
.selectionCard label.loading > div input[type='radio']:after,
.selectionCard label.disabled > div input[type='radio']:after {
  box-shadow: 0 0 0 1px var(--checkbox-radio-border);
}

.selectionCardContent {
  flex: 0.9;
  border-left: 1px dashed var(--border-color);
  padding-left: var(--xxlarge);
  padding-right: var(--xxlarge);
}

@media (max-width: 768px) {
  .selectionCardContent {
    padding-left: var(--medium);
    padding-right: var(--medium);
  }
}

.selectionCardContent span {
  font-weight: 600;
}

.selectionCardContent p {
  margin: 0;
  margin-top: var(--xsmall);
  font-size: 16px;
}

.selectionCard button {
  background: transparent;
  border: none;
  text-align: left;
  width: 100%;
  padding: 0;
}

.selectionCard button:focus:not(:focus-visible) {
  outline: none;
}