/*
  TODO: Sätt ordentlig bredd/marginal som ska hålla sig genom hela appen.
  Glöm inte maxbredd/minbredd och ev. brytpunkter för mobil/liten skärm.
 */

* {
  font-family: var(--font-family);
}

.contentContainer {
  flex: 1 1 auto;
  width: 100%;
  box-sizing: border-box;
}

.scaledContent {
  transform: scale(1.0); /* Ändra skalfaktorn för att justera skalningen */
  transform-origin: top left; /* Ange ursprungspunkten för skalningen */
}


h1 {
  font-size: 1.6rem;
}

h2 {
  font-size: 1.4rem;
}


form label {
  display: block;
}

form input,
form select,
form textarea,
textarea {
  position: relative;
  padding: var(--small);
  display: block;
  margin-top: var(--small);
  width: 100%;
  font-size: 14px;
  box-sizing: border-box;
}

.formWrapperAHalf {
  width: 50%;
}

input[type='checkbox'],
input[type='radio'] {
  width: auto;
  display: inline-block;
  margin-right: var(--small);
}

/* Flex */

.LonesokFlexBox {
  display: flex;
}

.LonesokFlexCol {
  flex-grow: 1;
}

@media print {
  @page {
    size: A4;
    margin: 7mm 0;
  }
  html,
  body {
    width: 220mm;
    height: auto;
    background-color: #fff;
    margin: 0;
    padding: 0;
  }
}

