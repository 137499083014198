.modaldarkBG {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.modalCentered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal {
  max-width: 650px;
  height: auto;
  background: white;
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}

.modalHeader {
  height: 40px;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.modalHeading {
  margin: 0;
  padding: 20px;
  color: #2c3e50;
  font-weight: bold;
  font-size: 18px;
  text-align: left;
}

.modalBody {
  height: 100px;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.modalMessage {
  margin: 0;
  padding: 20px;
  color: #2c3e50;
  font-weight: 500;
  font-size: 18px;
  text-align: left;
}


.modalContent {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
  color: #2c3e50;
  text-align: left;
}

.modalButtons {
  padding: 20px;
  font-size: 14px;
  color: #2c3e50;
  text-align: right;
}

.modalactionsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.modalBtn {
  margin-right: 0px;
  margin-left: 5px;
  margin-top: 10px;

  cursor: pointer;
  font-weight: 500;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: #99d2d9;
  transition: all 0.25s ease;
  min-width: 100px;
}

.modalButton:hover {
  transform: translateY(-5px);
  background: red;
}

