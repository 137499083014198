.regularPanel {
  background-color: white;
  border: 1px solid var(--border-color);
  padding: var(--medium);
  margin-bottom: var(--large);
  box-sizing: border-box;
}

.regularPanel.isAdmin {
  border: var(--border-admin-card);
  border-radius: 2px;
}

.regularPanel h2 {
  margin-top: 0;
}

.panelFieldset {
  border: var(--border-admin-card);
  border-radius: 2px;
  padding: var(--medium);
  margin-bottom: var(--large);
  box-sizing: border-box;
}

.panelLegend {
  color: var(--light-blue);
  font-size: 18px;
  display: inline-block;
  padding: var(--small);
  box-sizing: border-box;
}