.filterExplanationCreate {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-color);
  padding: var(--medium);
  background-color: white;
  margin-top: var(--large);
  margin-bottom: var(--medium);
}

.filterExplanationHeading {
  margin: 0;
}

.textArea {
  margin: var(--large) 0;
}
