.loader {
    display: block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: 100px auto;
  }

  .loader div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: var(--secondary-color);
    border: 1px solid var(--text-color);
    animation: loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  
  .loader div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }

  .loader div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }

  .loader div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }

  @keyframes loader {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }

  .loader.isSmall {
    margin: 0 auto;
  }

  .loader.isSmall div {
    left: 4px;
    width: 8px;
    animation: loaderSmall 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }

  .loader.isSmall div:nth-child(1) {
    left: 4px;
    animation-delay: -0.24s;
  }

  .loader.isSmall div:nth-child(2) {
    left: 18px;
    animation-delay: -0.12s;
  }

  .loader.isSmall div:nth-child(3) {
    left: 32px;
    animation-delay: 0;
  }
  
  @keyframes loaderSmall {
    0% {
      top: 4px;
      height: 32px;
    }
    50%, 100% {
      top: 12px;
      height: 16px;
    }
  }