.defaultText {
  font-style: italic;
}

.optionsList {
  padding: var(--medium);
}

.optionsList .label {
  display: block;
  font-weight: bold;
}

.optionsList textarea {
  width: 100%;
  margin-bottom: var(--medium);
}

.optionsList h3 {
  margin-top: var(--large);
  margin-bottom: var(--small);
}

.defaultOption {
  float: left;
  padding: 2px 10px 0;
}
