.main {
  max-width: 450px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 var(--medium);
  /* This is a hack because body cant be 100% height no more. Then embedding the site works poor */
  min-height: calc(100vh - 460px)
}

.header {
  color: var(--teal);
  font-weight: bold;
  font-size: 42px;
  margin: 0;
  text-align: center;
}

.text {
  font-size: 22px;
  text-align: center;
}