.inputWrapper {
  margin-bottom: var(--medium);
}

.label {
  margin-bottom: var(--small);
}

.input {
  border-radius: var(--border-radius-subtle);
  border: 1px solid var(--text-color);
  box-sizing: border-box;
  font-size: 1rem;
  margin: 0;
  padding: var(--small);
  width: 100%;
}

.input .number {
  font-size: 1rem;
  height: var(--large);
}

/* Chrome, Safari, Edge, Opera */
.number::-webkit-outer-spin-button,
.number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.number[type=number] {
  -moz-appearance: textfield;
}

.text {
  font-size: 14px;
}
