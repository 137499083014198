.sectionAdmin {
  background-color: white;
  grid-gap: var(--medium);
  grid-template-columns: 0.5fr 1fr;
  border: var(--border);
  margin-bottom: var(--xlarge);
}

.sectionHeading {
  background-color: var(--primary-color);
  padding: var(--medium);
  margin: 0;
}

.sectionContent {
  padding: 0 var(--medium);
}