.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
}

.errorBox {
  max-width: 700px; /* Ökad bredd för att passa längre texter */
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.header {
  font-size: 32px; /* För tydlig rubrik */
  font-weight: bold;
  color: #008080; /* Teal färg */
  margin-bottom: 16px;
}

.text {
  font-size: 22px; /* För enhetlig stil */
  color: #555;
  line-height: 1.5;
  margin-bottom: 16px;
}

.link {
  color: var(--primary-color-dark);
    border-bottom: 1px dashed var(--primary-color-dark);
}

.link:hover {
  text-decoration: underline;
}

.footer {
  font-size: 22px;
  color: #555;
  margin-top: 16px;
}

.logo {
  min-width: 175px;
  max-height: 100px;
}
