.linkItem {
  background-color: #e9e9e9;
  border: var(--border);
  display: flex;
  flex-direction: column;
  padding: var(--medium);
}

.linkLabel {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  margin-bottom: var(--small);
}

.linkLabel + .linkLabel {
  margin-top: var(--medium);
}

.loaderAnimation {
  display: flex;
  justify-content: center;
  width: 100%;
}
