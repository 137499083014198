.main {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.wrapForm {
  max-width: 600px;
  margin-bottom: var(--large);
}

.selectionRow {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-color);
}

.subSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--large);
}

.subSection .arrowButton {
  margin-right: var(--xsmall);
}

.saveSection {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border: var(--border);
  background-color: var(--bg-grey);
  border-radius: 5px;
  padding: var(--small);
  margin-bottom: var(--large);
}

.saveSection > button + button {
  margin-left: var(--small);
}
