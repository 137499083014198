.selectBox {
  position: relative;
  margin-top: 10px;
}

.selectLabel {
  font-size: 13px;
  display: block;
  position: absolute;
  top: -11px;
  left: 5px;
  z-index: 10;
  background: white;
  box-sizing: border-box;
  padding: 2px 4px;
}

.selectLabel.isShaded {
  background: white;
  background: linear-gradient(to bottom, #f8f8f8, #fff);
}

.selectBox.hasMarginBottom {
  margin-bottom: var(--medium);
}

.selectWrapper {
  position: relative;
}

.selectWrapper:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid var(--primary-color-dark);
  content: '';
  position: absolute;
  right: var(--medium);
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.selectBox.disabled .selectWrapper:after {
  border-top: 8px solid var(--border-color-disabled);
}

.selectWrapper select {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  font-size: 14px;
  padding: 15px 30px 15px 15px;
  width: 100%;
  margin: 0;
  border: 1px solid var(--text-color);
  border-radius: var(--border-radius-subtle);
  box-sizing: border-box;
}

@media(max-width: 768px) {
  .selectWrapper select {
    font-size: 16px;
  }
}

.selectBox.disabled {
  pointer-events: none;
}

.selectBox.disabled select {
  border-color: var(--border-color-disabled);
}

.selectBox.isCompact .selectWrapper select {
  padding: 14px 30px 14px 15px;
}

.loaderWrapper {
  position: absolute;
  background-color: var(--bg-white-see-through);
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.loaderWrapper .selectLoader {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}