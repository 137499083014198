.analysisCard {
  display: flex;
  flex-direction: column;
  background-color: white;
  text-align: center;
  padding: var(--large);
  border: var(--border);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.analysisCard:hover {
  background-color: #fdfdfd;
}

.analysisCard h3 {
  margin: 0;
  margin-top: var(--large);
}

.analysisCard p {
  margin: 0;
  margin-top: var(--small);
}

.analysisCard span {
  margin-top: var(--xlarge);
  font-weight: 600;
}

.continueLink:hover {
  text-decoration: underline;
}

.cardSymbol {
  position: relative;
  width: 90%;
  margin: 0 auto;
  transition: transform 0.2s; /* Animation */
}

.analysisCard:hover .cardSymbol {
  transform: scale(1.03);
}

.simpleOption {
  border-bottom: var(--small) solid var(--primary-color);
}

.advancedOption {
  border-bottom: var(--small) solid var(--secondary-color);
}

.simpleOption span {
  color: var(--primary-color-dark);
}

.advancedOption span {
  color: var(--secondary-color-dark);
}

.analysisCard span svg {
  vertical-align: middle;
}