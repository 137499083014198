.buttonLink {
  background-color: transparent;
  color: var(--primary-color-dark);
  cursor: pointer;
  text-decoration: dashed;
  border: 0;
  padding: 5px;
}

.buttonLink .buttonIcon {
  position: relative;
  top: 3px;
  margin-right: 4px;
}

.buttonLink .buttonText {
  font-size: 16px;
  display: inline-block;
  border-bottom: 1px dashed var(--primary-color-dark);
  box-sizing: border-box;
}

.buttonLink.isLarge .buttonText {
  font-size: 20px;
  color: black;
  border-bottom: 1px dashed black;
}

.buttonLink.isLarge .buttonIcon {
  top: 4px;
  margin-right: 8px;
  border-color: black;
}

.buttonLink:hover .buttonText {
  border-bottom: 1px solid var(--primary-color-dark);
}

.buttonLink.isLarge:hover .buttonText {
  border-bottom: 1px solid black;
}

.buttonLink:disabled {
  color: var(--text-color-disabled);
  pointer-events: none;
}

.buttonLink:disabled .buttonText {
  border-bottom: 1px dashed var(--text-color-disabled);
}

.buttonLink.isMenuItem {
  width: 100%;
  padding: var(--large);
  vertical-align: middle;
  text-align: left;
  border-bottom: 1px dashed var(--border-color);
  box-sizing: border-box;
}

.buttonLink.isMenuItem.isLastChild {
  border-bottom: transparent;
}

.buttonLink.isMenuItem:hover {
  background-color: var(--bg-grey-darker);
}

.buttonLink.buttonLink.isMenuItem .buttonText,
.buttonLink.buttonLink.isMenuItem:disabled .buttonText {
  border-bottom: 0;
}

.buttonLink.buttonLink.isMenuItem:hover .buttonText {
  border-bottom: 0;
}

.buttonLink.isAccordionItem {
  width: 100%;
  padding: 16px 0;
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px dashed var(--primary-color-dark);
  box-sizing: border-box;
  background-color: var(--bg-grey);
}

.buttonLink.isAccordionItem .buttonText {
  border-bottom: 1px solid transparent;
}

.buttonLink.isAccordionItem:hover {
  background-color: var(--bg-grey-darker);
  border-bottom: 1px solid var(--primary-color-dark);
}
