.button {
  cursor: pointer;
  font-size: 14px;
  max-height: 41px;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  line-height: 30px;
  user-select: none;
}

.button:disabled {
  pointer-events: none;
}

.button .buttonIcon {
  position: relative;
}

.button.hasText .buttonIcon {
  padding-right: var(--xsmall);
  top: -1px;
}

.button.hasText .buttonText {
  position: relative;
  top: -6px;
}

.button.hasIcon.hasText.flipIcon {
  direction: rtl;
}

.button.hasIcon.hasText.flipIcon .buttonIcon {
  padding-left: var(--xsmall);
  padding-right: 0;
}

.button.primary {
  color: white;
  background-color: var(--dark-blue);
  border: 2px solid var(--dark-blue);
  border-radius: 50px;
  padding: var(--small);
}

.button.primary:hover {
  background-color: var(--dark-blue-hover);
}

.button.primary:disabled {
  background: var(--bg-grey-darker);
  border-color: var(--text-color-disabled);
  color: var(--text-color-disabled);
} 

.button.secondary {
  background-color: white;
  border-radius: 52px;
  padding: var(--small);
  border: 2px solid var(--dark-blue);
  color: var(--dark-blue);
}

.button.secondary:hover {
  background-color: var(--light-blue-bg);
  border-color: var(--dark-blue-hover);
  color: var(--dark-blue-hover);
}

.button.secondary:disabled {
  border-color: var(--text-color-disabled);
  color: var(--text-color-disabled);
  background-color: var(--bg-grey);
}

.button.ghost {
  background-color: transparent;
  border-radius: 52px;
  padding: var(--small);
  border: 2px solid white;
  color: white;
}

.button.ghost:hover {
  border-color: var(--bg-grey-darkest);
  color: var(--bg-grey-darkest);
}

.button.ghost:disabled {
  border-color: var(--text-color-disabled);
  color: var(--text-color-disabled);
}

.button.delete {
  background-color: transparent;
  border-radius: 52px;
  padding: var(--small);
  border: 2px solid var(--error);
  color: var(--error);
}

.button.delete:hover {
  border-color: var(--bg-grey-darkest);
  color: var(--bg-grey-darkest);
}

.button.delete:disabled {
  border-color: var(--text-color-disabled);
  color: var(--text-color-disabled);
}