
:root {
  --border-radius-container: 16px;
  --border-radius-select: 8px;
  --color-label: #ffffff;
  --color-text: #ffffff;
  --color-selection:#c13d8c ;
  --color-background: #008ea1;
  --color-backgroundHeader: #006371;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 0;
  background: var(--color-background);
  border-radius: var(--border-radius-container)
}

.activeIcon{
  position: relative;
  color: var(--color-selection);
  z-index: 1;
  pointer-events: none;
  font-style: bold;
  left: 83%;
  bottom: 40px;
  font-family: 'CustomFont', sans-serif;
}

.selectBox {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 0px !important;
}

.selectBox input{
  border-radius: 8px;
  height: 20px;
  margin-right: 20px;
}

.selectBox > div {
  display: flex;  
  flex-direction: column;
  flex: 1;
}

.selectBox label{
  color: var(--color-label);
  font-family: 'CustomFont', sans-serif;
  font-size: 18px;
  padding-bottom: 2px;
}

.input {
  min-height: 50px;
  border: 0px;
  font-family: 'CustomFont', sans-serif;
  font-size: 18px;
}

.header {
  background: var(--color-backgroundHeader); ;
  min-height: 50px;
  border-top-left-radius: var(--border-radius-container);
  border-top-right-radius: var(--border-radius-container);
  margin-bottom: 20px;
}

.header p {
margin-left: 30px;
color: white;
font-family: 'CustomFont', sans-serif;
font-size: 20px;
}

.chart > div{
  background: white;
  margin: 0px 25px 0px 25px;
  padding: 20px 0px 0px 0px;
  position: relative;
  border: 10px solid white;
  border-radius: 12px;
}

.chart canvas {
  height: 300px !important;
}

.monthlySalary,
.salaryPercentage{
  display: table;
  margin: 0 auto;
  font-family: 'CustomFont', sans-serif;
}

.salaryPercentage {
  font-size: 25px;
  color: var(--color-text);
  margin-bottom: 2px;
  height: 40px;
}

.salaryPercentage span{
  font-size: 45px !important;
  font-family:'CustomFont2', sans-serif !important;
}

.source{   
  font-size: 13px;
  font-family: 'CustomFont', sans-serif;
  margin: 10px 25px 10px 25px;
  color: var(--color-text);
  float: right;
}
.monthlySalary{
  font-size: 17px;
}

.result{
  width: 32%;
  height: 40%;
  position: absolute;
  top: 0%;
  left: 70%;
  background: transparent;
}

.result span{
 font-size: 18px;
 color: var(--color-text);
 font-family: 'CustomFont', sans-serif;
 /*margin: 0px;*/
 /*padding: 0px;*/
 text-align: center;
 font-size: 18px;
}

/* Chat bubble container */
.chat {
  position: relative;
  padding: 10px;
  text-align: center;
  color: black;
  background: white;
  border-radius: 6px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  max-height: 21px;
  margin: 13% 34% 58px 4% !important;
}

/* Arrow pointing to the left */
.chat:before {
  content: "";
  font-family: 'CustomFont', sans-serif;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: -10px; /* Flytta pilen till vänster sida */
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 15px solid white; /* Pilen pekar åt vänster */
  transform: translateY(-50%); /* Centrera pilen vertikalt */
}

/* Klass med pil till vänster */
.arrowleft {
  margin-top: 35px;
  margin-left: -12px;
  font-family: 'ProximaNova-Semibold' !important;
}
.arrowleft img {
  font-family: 'ProximaNova-Semibold' !important;
}

@media(min-width: 600px) {
  .selectBoxLeft{
    margin-left: 25px;
    margin-right: 12px;
  }

  .selectBoxRight{
    margin-right: 25px;
    margin-left: 12px;
  }

  .speechBubbleContent{
    display: flex;
    flex-direction: row; 
    position: relative;
    margin-bottom: 23px;
    
  }

  .speechBubbleBoxLeft,
  .speechBubbleBox{
    display: flex;  
    flex-direction: column;
    flex: 1;
    position: relative;
  }
  .speechBubbleBoxLeft > div,
  .speechBubbleBox > div{
    right: 2px;
    position: absolute;
  }

  .speechBubbleBoxLeft{
    margin-right: 20px;
  }

  .speechBubbleBoxLeft img,
  .speechBubbleBox img{
    height: 40px;
    font-family: 'ProximaNova-Semibold' !important;
  }
}

 /* Stilar för stora skärmar (desktop) */
@media(min-width: 1025px) {
  .loaderAnimation,
  .container{
    max-width: 630px;
}
}
  /* Stilar för mellanstora skärmar (surfplatta) */
@media (min-width: 600px) and (max-width: 1024px) {
  .outerContainer,
  .loaderAnimation,
  .container{
    max-width: 630px;
  } 

}

 /* Stilar för små skärmar (mobil) */
@media(max-width: 600px) {
  .source{   
    margin: 10px 13px 10px 25px;
  }
  .speechBubbleContent{
    display: none;
  }
  .header p {
    margin-left: 15px;
  }
  
  .chart > div{
    margin: 0px 12px 0px 12px;
    padding: 20px 0px 0px 0px;
  }

  .chart p{
    line-height: 1; 
  }
  
  .selectBox {
    display: flex;
    flex-direction: column;
    margin: 0px 12px 0px 12px !important;
  }
  
  .loaderAnimation,
  .container {
    display: flex;
    flex-direction: column;
  }

  .arrowleft{
    display: none;
  }
    
  .activeIconRight{
    left: 80% !important;
    color: green;
  }
  .activeIcon{
    left: 90%;
    }

    .chart {
      position: relative;

    }
    
  .chart canvas {
    height: 200px !important;
  }
  
  .chart > div{
    margin-top: 15px;
   }
   
   .result {
    width: 55%;
    height: 20px;
    top: -120px;
    left: 48%;
    background: transparent;
    color: white;

   }
  
  .salaryPercentage span{
    font-size: 34px !important;
  }

  .marginBottom{
    margin-bottom: -20px;
  }

}