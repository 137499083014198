.container {
  width: 100%;
}

.tableContainer {
  /* 3 rows in thead, 22 rows in tbody, 3px for horizontal scrollbar */
  max-height: calc((25 * 37px) + 4px);
  overflow: auto;
  width: 100%;
  margin-bottom: var(--medium);
}

.tabRow {
  margin-bottom: var(--small);
}

.table {
  align-self: flex-start;
  background-color: #f1f1f1;
  border-collapse: separate;
  border-spacing: 0;
  display: table;
  overflow: auto;
  text-align: start;
  white-space: nowrap;
  width: 100%;
  height: 100%;
}

.table thead {
  position: sticky;
  top: 0;
  z-index: 10;
}

.table th,
.table td {
  font-size: 14px;
  padding: var(--small);
  text-align: start;
  max-width: 4vw;
  overflow: hidden;
  text-overflow: ellipsis;
  border-right: 1px solid var(--border-color);
}

.table th {
  background-color: #f1f1f1;
  border-right: 1px solid #707070;
  position: relative;
  z-index: 1;
  box-shadow: inset 0px -1px 0px #707070;
}

.table th:last-child {
  border-right: none;
}

.table th.sticky {
  position: sticky;
  left: 0;
  z-index: 10;
}

.table th.center {
  text-align: center;
}

.table tr {
  box-shadow: inset 0px -1px 0px var(--border-color);
}

.table tr td:first-child {
  position: sticky;
  left: 0;
  box-shadow: inset 0px -1px 0px var(--border-color);
}

.table td:first-child {
  width: auto;
  white-space: break-spaces;
  word-break: break-word;
}

.table tr:nth-child(odd), 
.table tr:nth-child(odd) td:first-child {
  background-color: #fafafa;
}

.table tr:nth-child(even), 
.table tr:nth-child(even) td:first-child  {
  background-color: #f1f1f1;
}

.table tbody td + td, .table tfoot td + td {
  text-align: right;
}

.table thead th + th {
  text-align: right;
}

.table tfoot td {
  font-weight: bold;
}

.bottomRowPrint {
  display: none;
}

@media screen {
  .tableWrapperPrint {
    display: unset;
  }

}
@media print {
  .tableContainer {
    border-top: 1px solid var(--border-color);
    max-height: initial;
    margin: 0;
  }

  .table {
    display: table !important;
    height: auto !important;
    width: 100% !important;
    break-inside: auto;
  }

  .table thead {
    display: table-header-group;
  }

  .table tbody {
    display: table-row-group;
    break-inside: auto;
  }

  .table tfoot {
    display: table-footer-group;
  }

  .tablefoot {
    font-weight: bold;
    border-top: 1px solid var(--border-color);
  }

  .table tr {
    break-inside: avoid;
    break-after: auto;
  }

  .tabRow {
    margin-bottom: 0;
  }
}