.externalLink {
  align-items: center;
  background-color: white;
  box-shadow: var(--box-shadow);
  color: var(--primary-color-dark);
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  padding: var(--xlarge) var(--xxxlarge);
}

.externalLink:hover {
  background-color: var(--bg-grey-darker);
}

.externalLink svg {
  color: var(--primary-color-dark);
}