.rowContainer {
    margin: 5px 0;
    width: 100%;
    text-align: left;
    border: var(--border);
    border-radius: 5px;
    padding: 4px;
    color: var(--text-color);
    background-color: var(--bg-grey);
}
.header {
    display: flex;
    color: var(--dark-blue);
    border-bottom: 1px solid var(--light-blue);
}
.rowContainer p {
    margin: 5px 15px;
}
.source {
}
.textTitle {
    color: var(--light-blue);
}

.bottomWrapper {
    width: 100%;
    height: 61px;
}
.buttonContainer {
    position: relative;
    top: -70px;
    margin-bottom: -61px;
}
.buttonContainer button {
    margin: 10px;
}