:root {
  --primary-color: #99d2d9; /* Mörk: 0199AB, Ljus: 9CCFD6 på SACO.SE, LOGO: 018FA6, i heading: 008EA1 */
  --primary-color-dark: #277C7C;
  --secondary-color: #DA8BBA;
  --secondary-color-lighter: #E6B1D1;
  --secondary-color-light: #EAC2DC;
  --secondary-color-darker: #CD64A3;
  --secondary-color-dark: #C13D8C;
  --bg-grey: #fafafa;
  --bg-grey-darker: #f8f8f8;
  --bg-grey-darkest: #e4e4e4;
  --bg-white-see-through: #ffffff5f;
  --border-color: #dddddd;
  --border-color-disabled: #e2e2e2;
  --border: 1px solid var(--border-color);
  --border-dashed: 1px dashed var(--border-color);
  --border-dashed-thick: 2px dashed var(--border-color);
  --border-admin-card: 1px solid var(--light-blue);
  --border-radius: 6px;
  --border-radius-subtle: 3px;
  --checkbox-radio-border: #333333;
  --checkbox-radio-border-disabled: #b5b5b5;
  --checkbox-radio-bg: white;
  --checkbox-radio-bg-hover: #dddddd;
  --checkbox-radio-bg-disabled: #ebebeb;
  --text-color: #333333;
  --text-color-disabled: #969696;
  --light-blue: #9dbbc0;
  --light-blue-bg: #bdd6dab0;
  --dark-blue: #33747c;
  --dark-blue-hover: #2a5f66;
  --teal: #008ea1;
  --warning: #ff9f9f;
  --error: #ce3333;
  --scrollbar-background-color: #424242;
  --scrollbar-thumb-color: #a0a0a0;
  --scrollbar-track-background-color: #d8d8d8;

  --box-shadow: 0px 3px 6px #00000029;

  --xxsmall: 2.5px;
  --xsmall: 5px;
  --small: 10px;
  --medium: 15px;
  --large: 20px;
  --xlarge: 25px;
  --xxlarge: 30px;
  --xxxlarge: 35px;
  --xxxxlarge: 40px;

  --font-normal: 1.2rem;
  --font-large: 1.4rem;

  --outer-container-max-width: 1300px;
  --inner-container-max-width: 768px;
  --filter-section-max-height-mobile: 400px;
  --filter-section-max-height-desktop: 800px;

  --font-family: 'Lato', sans-serif;
  --font-weight-bold: 600;
}
