.breadcrumb {
  display: inline;
}

.breadcrumbInactive {
  color: var(--primary-color-dark);
  border-bottom: 1px dashed var(--primary-color-dark);
  box-sizing: border-box;
  display: inline-block;
}

.breadcrumbInactive:hover {
  border-bottom: 1px solid var(--primary-color-dark);
}

.breadcrumbActive {
  color: var(--text-color);
  user-select: none;
  font-size: 16px;
  display: inline;
  font-weight: 300;
}