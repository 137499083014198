@import './variables.css';

html,
#root {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: var(--bg-grey);
  font-weight: 300;
  font-size: 16px;
  color: var(--text-color);
}

#root {
  display: flex;
  flex-direction: column;
}

a {
  /* FIXME: Lösning för router links. */
  text-decoration: none;
  color: black;
}

a.generalLink {
  color: var(--primary-color-dark);
  text-decoration: 1px dashed underline;
  text-underline-offset: 2px;
}

a.generalLink:hover {
  text-decoration: solid underline;
}

ul.generalList,
ul.generalList ul {
  padding-left: 20px;
  margin-left: 10px;
}

ul.generalList ul {
  margin-bottom: 5px;
}

ul.generalList li {
  line-height: 20px;
  margin-bottom: 5px;
}

ul.generalList ul li {
  line-height: 20px;
  margin-bottom: 0;
}

*:focus {
  outline-color: var(--primary-color-dark);
}

::-webkit-scrollbar {
  width: 6px;
  height: 4px;
  background-color: var(--scrollbar-background-color);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  outline: 0;
  background-color: var(--scrollbar-thumb-color);
}
::-webkit-scrollbar-thumb:hover {
  background-color: #868686;
}

::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-background-color);
}

::placeholder {
  color: var(--light-blue);
}

body::-webkit-scrollbar {
  width: 10px;
  height: 7px;
}
