.statisticsometer {
  align-items: center;
  background-color: white;
  border-radius: var(--border-radius-subtle);
  border: 1px solid var(--text-color);
  box-sizing: border-box;
  display: flex;
  max-height: 55px;
  position: relative;
  width: 100%;
}

.statisticsometer.hasMarginBottom {
  margin-bottom: var(--xlarge); 
}

.meter {
  border-radius: var(--border-radius-subtle) 0 0 var(--border-radius-subtle);
  height: 100%;
  position: absolute;
  transition: 0.6s;
}

.meter.full {
  border-radius: var(--border-radius-subtle);
}

.meter.overview {
  background-color: var(--primary-color);
}

.meter.advanced {
  background-color: var(--secondary-color);
}

.statisticsText {
  background-color: white;
  border-radius: 19px;
  border: 1px solid var(--text-color);
  font-size: 12px;
  margin: var(--medium);
  padding: var(--xxsmall) var(--small);
  position: relative;
  width: fit-content;
  z-index: 10;
}

@media (min-width: 991px) {
  .statisticsText {
    font-size: 14px;
  }
}
