.chart {
  margin: 30px 0;
}

.chartContainer {
  margin-top: 50px;
}

.header {
  background-color: var(--bg-grey-darker);
  margin: 0 50px;
  padding: 25px;
  text-align: center;
}

table {
  border-collapse: collapse;
  font-size: 13px;
}

th,
td {
  padding: 0.5em;
  border: 1px solid #ccc;
}

th {
  text-align: center;
}

.tableToggle {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #008ea1;
  color: white;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
}