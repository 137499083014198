@media print {
  .helpSection {
    display: none;
  }
}

.helpSection a{
  cursor: pointer;
  color: var(--primary-color-dark);
}

.helpSection a:hover {
  text-decoration: underline;
}
