.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.container input[type='radio'] {
  appearance: none;
  cursor: pointer;
  margin: 0;
}

.container input[type='radio']:disabled {
  pointer-events: none;
}

.container input[type='radio']:focus:not(:focus-visible) {
  outline: none;
}

/* .container input[type='radio']:after {
  cursor: pointer;
  width: 22px;
  height: 22px;
  box-sizing: border-box;
  border-radius: var(--large);
  position: relative;
  background-color: white;
  content: '';
  display: inline-block;
  border: 2px solid white;
  box-shadow: 0 0 0 1px #707070;
} */

/* .container input[type='radio']:checked:after {
  pointer-events: none;
  width: 22px;
  height: 22px;
  box-sizing: border-box;
  border-radius: var(--large);
  position: relative;
  background-color: var(--primary-color);
  content: '';
  display: inline-block;
  border: 2px solid var(--primary-color);
  box-shadow: 0 0 0 1px var(--checkbox-radio-border);
} */

.container input[type='radio']:disabled:after {
  pointer-events: none;
}

.icon {
  display: none;
}

.container input[type='radio']:checked + .icon { /* checbox check is just a css border - do the same here? */
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  stroke-width: 4px;
  margin-top: -1px;
}

/* Create a custom radio button --------------------- */
.container .checkmarkWrapper {
  background-color: var(--checkbox-radio-bg);
  border: 1px solid var(--checkbox-radio-border);
  border-radius: 100%;
  box-sizing: border-box;
  margin-top: -11px;
  position: absolute;
  top: 50%;
  height: 22px;
  width: 22px;
  overflow: hidden;
}

.container input:focus ~ .checkmarkWrapper,
.container input:focus-visible ~ .checkmarkWrapper {
  outline: 2px solid var(--primary-color-dark);
  outline-offset: 1px;
}

.container input:focus:not(:focus-visible) {
  outline: none;
}

.container .customCheckmark {
  border: 2px solid transparent;
  border-radius: var(--border-radius-subtle);
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
}

.container:hover .customCheckmark {
  background-color: var(--checkbox-radio-bg-hover);
  border: 3px solid var(--checkbox-radio-bg);
  border-radius: 100%;
}

.container:hover input:checked ~ .checkmarkWrapper .customCheckmark {
  background-color: transparent;
}

@media(max-width: 768px) {
  .container:hover .customCheckmark {
    background-color: transparent;
  }
}

.container input:checked ~ .checkmarkWrapper,
.container:hover input:checked ~ .checkmarkWrapper {
  background-color: var(--primary-color);
}

.container input:checked ~ .checkmarkWrapper .customCheckmark {
  border-color: var(--primary-color);
}

.container input:checked ~ .checkmarkWrapper .customCheckmark:before  {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: var(--border-radius-subtle);
  border: 2px solid transparent;
}

.container.loading .checkmarkWrapper,
.container.disabled .checkmarkWrapper {
  border-color: var(--checkbox-radio-border-disabled)
}

.container.loading .customCheckmark,
.container.disabled .customCheckmark {
  background-color: var(--checkbox-radio-bg-disabled);
  border-color: var(--checkbox-radio-bg-disabled);
}

.container.loading .customCheckmark:before,
.container.disabled .customCheckmark:before {
  border-color: transparent;
}

.container.loading input:checked ~ .checkmarkWrapper .customCheckmark,
.container.disabled input:checked ~ .checkmarkWrapper .customCheckmark  {
  background-color: var(--checkbox-radio-bg-disabled);
  border-color: var(--checkbox-radio-bg-disabled);
}

.container.loading input:checked ~ .checkmarkWrapper .customCheckmark:before,
.container.disabled input:checked ~ .checkmarkWrapper .customCheckmark:before{
  border: 0;
}

/* Create the checkmark/indicator (hidden when not checked) */
.container .customCheckmark:after {
  content: '';
  display: none;
  position: absolute;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmarkWrapper .customCheckmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .customCheckmark:after {
  border: solid var(--text-color);
  border-width: 0 3px 3px 0;
  height: 10px;
  left: 5px;
  top: 0;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 4px;
}

.container.loading .customCheckmark:after,
.container.disabled .customCheckmark:after  {
  border-color: var(--checkbox-radio-border-disabled);
}

