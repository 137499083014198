
.slider {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
    padding: 0px 0;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 32px;
    width: 62px;
    cursor: pointer;
    margin-top: 0px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    border-radius: 2px;
    background: #C13D8C;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><path d="M10.668 5.22778L6.40317 9.46294L10.6383 13.7277" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'), url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><path d="M6.39453 12.7798L10.6643 8.54966L6.43419 4.27988" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    background-repeat: no-repeat;
    background-position: left 15px top 50%, right 15px top 56%;
    background-size: 18px auto; /* Ställ in storleken på bakgrundsbilderna */



  }
  

  
  
  /* input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px; 
    height: 30px; 
    background: #4CAF50; 
    cursor: pointer;
    border-radius: 50%; 
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 4l1.41 1.41L8.83 10H22v2H8.83l4.58 4.59L12 18l-8-8z"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
  }
  
  input[type="range"]:focus::-webkit-slider-thumb {
    outline: none; 
  } */


  /* All the same stuff for Firefox */
  .slider::-moz-range-thumb {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border: 2px solid var(--primary-color);
    height: 32px;
    width: 62px;
    background: #ffffff;
    cursor: pointer;
  }
  
  /* All the same stuff for IE */
  .slider::-ms-thumb {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border: 2px solid var(--primary-color);
    height: 32px;
    width: 62px;
    border-radius: 51px;
    background: #ffffff;
    cursor: pointer;
  }
  
  .slider:focus:not(:focus-visible) {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }
  
  .slider::-ms-track {
    width: 100%;
    cursor: pointer;
    height: 32px;
  
    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  
    border-width: 16px 0;
  }
  
  .slider::-webkit-slider-runnable-track {
    width: 100%;
    height: 32px;
    cursor: pointer;
    background: #FFF;
    border-radius: 7px;
    
  }
  
  .slider:focus::-webkit-slider-runnable-track {
    /* background: var(--border-color); */
    background: #FFF;
  }
  
  .slider::-moz-range-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    background: var(--border-color);
    border-radius: 7px;
  }
  
  .slider::-ms-fill-lower {
    background: var(--border-color);
    border-radius: 7px;
  }
  
  .slider:focus::-ms-fill-lower {
    background: var(--border-color);
  }
  
  .slider::-ms-fill-upper {
    background: var(--border-color);
    border-radius: 7px;
  }
  
  .slider:focus::-ms-fill-upper {
    background: var(--border-color);
  }


  