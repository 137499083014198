.reportSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid var(--border-color);
  padding: var(--medium);
  overflow-x: auto;
  overflow-y: hidden;
}

@media(max-width: 768px) {
  .reportSection {
    padding: 0;
  }
}

.noData {
  margin: 131px 0;
  padding: 0 var(--medium);
  text-align: center;
}

/* Loader settings, to be reviewed */
.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 100px 0;
}

.loader div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: var(--secondary-color);
  border: 1px solid var(--text-color);
  animation: loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.loader div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}

.loader div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}

.loader div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}

@keyframes loader {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

@media print {
  .reportSection {
    display: block;
    padding: 0;
    border: 0;
    width: 100% !important;
    height: auto !important;
  }
}
