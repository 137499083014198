@font-face {
  font-family: 'Proxima Nova';
  src: url('/preview/ProximaNova-Regular.woff') format('opentype');
}

@font-face {
  font-family: 'ProximaNova-SemiBold';
  src: url('/preview/ProximaNova-Semibold.woff') format('opentype');
}


.UnemploymentspinTab {
  /*  align-self: flex-end;
    margin: 0;
    padding: var(--small) var(--medium); */
    font-family: 'ProximaNova-SemiBold';
    margin-right: 4px;
    width: 156px;
    height: 56px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #058FA2;
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    border: none;
  }
  
  .UnemploymentspinTab:focus:not(:focus-visible) {
    outline: none;
  }

  .UnemploymentspinTabActive {
    background-color: #F7F7F7;
    box-shadow: 1px 0 0 0 var(--border-color), 0 1px 0 0 #F7F7F7,
      1px 1px 0 0 var(--border-color), 1px 0 0 0 var(--border-color) inset,
      0 1px 0 0 var(--border-color) inset;
    color: #000000;
    pointer-events: none;

  }

  .UnemploymentspinTabPassive:hover {
    background-color: #F7F7F7;
    color: black;
  }
  
  .icon {
    width: 24px; /* Justera bredden efter behov */
    height: 24px; /* Justera höjden efter behov */
    margin-right: 10px; /* Justera höjden efter behov */
    margin-bottom: 5px;
  }


  @media print {
    .UnemploymentspinTab {
      box-shadow: none;
    }
    .UnemploymentspinTabPassive {
      display: none;
    }
  }