.statusBox {
  background-color: aliceblue;
  border: var(--border-dashed-thick);
  padding: var(--large);
  border-radius: var(--border-radius);
  margin-top: var(--medium);
}

.statusBox h3 {
  margin-top: 0;
  background-color: aliceblue;
}
.loaderAnimation {
  width: 50px;
  height: 20px;
  display: flex;
  justify-content: center;
}
.loaderWrapper .selectLoader {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.ul
{
    list-style-type: none;
}

.userFilters {
  background-color: #ffffff;
  border: 1px solid var(--border-color);
  margin-bottom: var(--medium);
}

.filterHeading {
  display: grid;
  grid-template-columns: 1fr auto;
  box-sizing: border-box;
  padding: 2px;
}

.filterHeading button {
  margin-bottom: -11px;
}

.filterHeading span svg {
  position: relative;
  top: 6px;
}

.indicatorSymbol {
  margin-right: var(--xsmall);
}

.resetFiltersBtn {
  margin-left: auto;
  margin-top: 2px;
  user-select: none;
  width: auto;
}

.disableMenuselection {
  background-color: lightgray;
}

@media print {
  .userFilters {
    display: none;
  }
}

/* 
.helpBoxUl {
  list-style-type: decimal;
  padding: 0;
  margin: 0 0 0 var(--medium);
}

.helpBoxUl > li {
  font-weight: bold;
  margin-bottom: var(--xsmall);
}

.helpBoxUl ul {
  list-style-type: disc;
  padding: 0;
  margin: 0 0 var(--xlarge) var(--large);
}

.helpBoxUl ul:last-child {
  margin-bottom: 0;
}

.helpBoxUl ul li {
  margin-bottom: var(--xsmall);
} */
