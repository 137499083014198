.explanationItem {
  border-bottom: var(--border-dashed-thick);
  padding: var(--medium) 0;
}

.explanationItem:first-child {
  padding-top: 0;
}

.explanationItem:first-child p:first-child {
  margin-top: 0;
}

.explanationItem:last-child {
  padding-bottom: 0;
}

.explanationItem:last-child {
  border: none;
}

.explanationItem h3 {
  margin: var(--small) 0 var(--medium) 0;
}

.image {
  width: 100%;
}
