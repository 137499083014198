.toolBar {
  border-bottom: var(--border-dashed);
  display: flex;
  margin-bottom: var(--medium);
}

.menuItem {
  padding: 0 18px 0 15px;
  margin-bottom: 7px;
  box-sizing: border-box;
  border-right: var(--border-dashed);
}

.menuItem:last-child {
  border-right: 0;
  padding-right: 0;
}

@media print {
  .toolBar {
    display: none;
  }
}

.toolBar > div {
  display: flex;
  flex-basis: 50%;
  justify-content: flex-end;
  position: relative;
}

.toolBar > div:first-child {
  justify-content: flex-start;
}

