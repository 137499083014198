.linksCard {
  border: var(--border);
  padding: var(--medium);
  background-color: white;
  margin-bottom: var(--large);
}

.linkBox {
  display: grid;
  grid-template-columns: 40% auto;
  grid-gap: var(--medium);
}
