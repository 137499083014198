.selectedSalary {
  font-weight: 2;
  padding: var(--small) 0;
}

.selectedSalary .number {
  margin-right: var(--small);
  width: 80px;
}

.selectedSalary .number {
  margin: 0 var(--small);
}