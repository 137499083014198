.main {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid var(--border-color);
  padding: var(--medium);
  margin-bottom: var(--medium);
}

.searchContainer {
  border-radius: var(--border-radius-subtle);
  margin-bottom: var(--small);
  min-width: 200px;
  width: 50%;
}

.filterTextList {
  border: var(--border);
  border-radius: var(--border-radius-subtle);
  padding: var(--large);
}

.filterTextsMissing {
  padding: var(--large);
  border: var(--border);
  border-radius: var(--border-radius-subtle);
  margin-bottom: var(--medium);
}
