.grid {
  display: grid;
  grid-gap: var(--medium);
  grid-template-columns: 1fr 210px;
  margin-bottom: var(--medium);
}

.grid .subHeader {
  display: block;
  font-weight: bold;
  grid-column: -1/1;
}

.grid textarea {
  padding: var(--xsmall);
  height: 220px;
  resize: both;
}

.left {
  float: left;
  padding: 2px 10px 0;
}
