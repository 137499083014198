.tabRow {
  margin-bottom: var(--medium);
}

.loaderAnimation {
  width: 100%;
  display: flex;
  justify-content: center;
}

.helpLink {
  margin-left: auto;
}

