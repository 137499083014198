.main {
  max-width: var(--outer-container-max-width);
  margin: 0 auto;
  margin-bottom: var(--xlarge);
}
.mainContent {
  max-width: var(--inner-container-max-width);
  margin: 0 auto;
  margin-bottom: var(--xlarge);
}

.innerContainerSlim {
  margin: 0 auto;
  padding: var(--xxxlarge) 0;
  max-width: var(--inner-container-max-width);
  padding: var(--large) var(--medium);
}

.optionCard {
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: white;
  padding: var(--medium);
  box-shadow: 0 var(--small) var(--medium) rgba(0, 0, 0, 0.2);
}

.optionCard h3 {
  margin: 0;
}

.optionCard div {
  margin-left: var(--medium);
}

.optionCard span {
  margin-top: var(--small);
  margin-left: auto;
  font-weight: bold;
}

.cardSymbol {
  height: 120px;
  font-weight: bold;
}

.advancedOption {
  border-bottom: var(--small) solid var(--secondary-color);
}
.advancedOption span {
  color: var(--secondary-color);
}
.optionCard span svg {
  vertical-align: middle;
}

.errorText {
  display: flex;
  width: 100%;
  margin-top: var(--large);
  font-weight: bold;
  font-size: 18pt;
}

.loaderAnimation {
  width: 100%;
  display: flex;
  justify-content: center;
}

.topNav {
  display: flex;
  border-bottom: 1px dashed var(--border-color);
  padding: var(--small) 0;
  margin-bottom: var(--medium);
}

