.main {
  margin: 0 auto;
  margin-bottom: var(--xlarge);
}

.mainSection {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: grid;
}

.innerContainerFull {
  margin: 0 auto;
  max-width: var(--outer-container-max-width);
  padding: var(--large) var(--medium);
}

@media (min-width: 768px) {
  .mainSection {
    grid-template-columns: 1fr 3fr;
    gap: var(--medium);
  }
}

.subSectionLeft,
.subSectionRight {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.subSectionContent {
  display: flex;
  flex-direction: column;
  width: 100%;
}


@media (min-width: 768px) {
  .reportSettings {
    display: grid;
    grid-template-columns: auto 280px;
    grid-template-rows: 55px;
    align-items: end;
    margin-bottom: var(--medium);
  }
}

@media print {
  .main {
    margin: 0;
  }
  .innerContainerFull {
    padding-top: 0;
    padding-bottom: 0;
  }
  .reportSettings {
    display: none;
  }
  .mainSection {
    display: block;
  }
}
