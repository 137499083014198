.main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header {
  display: grid;
  font-weight: bold;
  grid-template-columns: 1fr;
  padding: 0 var(--small) var(--small);
}

.header span:last-child {
  text-align: right;
}

@media (max-width: 768px) {
  .mobileEmpty, .greyed {
    display: none;
  }
}