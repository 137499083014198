.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin: 0 auto;
}

.innerContainer {
  margin: var(--small) auto;
  max-width: var(--inner-container-max-width);
  padding: 0 var(--medium);
}

.optionContainer {
  display: grid;
  grid-template-rows: 1fr;
  gap: var(--large);
}

@media (min-width: 768px) {
  .optionContainer {
    grid-template-columns: 1fr 1fr;
    column-gap: var(--large);
  }

  .aboutClippath {
    -webkit-clip-path: ellipse(100% 46% at 50% 100%);
    clip-path: ellipse(100% 46% at 50% 100%);
  }
}

.aboutCurveBackground {
  background-color: var(--bg-grey);
}

.aboutDropshadow {
  filter: drop-shadow(-1px -1px var(--border-color));
}

.aboutClippath {
  position: relative;
  width: 100%;
  height: 40px;
  background-color: #fff;
  -webkit-clip-path: ellipse(70% 46% at 50% 100%);
  clip-path: ellipse(70% 46% at 50% 100%);
}
