.activeFilters {
    background-color: #ffffff;
    padding: var(--small);
    margin-bottom: var(--medium);
    border: 1px dashed #707070;
    font-size: 14px;
    border-radius: 4px;
    box-sizing: border-box;
}

.activeFiltersHeading {
    font-weight: 600;
}

.filterName {
    font-style: italic;
    margin-right: var(--xsmall);
}

.optionName {
    display: inline-flex;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    color:inherit;
    padding: 0;
    margin-right: var(--xsmall);
    width: auto;
}

.optionName:hover {
    text-decoration: underline;
}

.optionName svg {
    align-self: flex-end;
}

.filterButtonWrapper {
    margin-right: 8px;
}