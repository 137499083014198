.loginContainer {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.main {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  width: 100%;
  padding: 25px;
  margin: 20px;
  border-radius: var(--border-radius-subtle);
  box-shadow: var(--box-shadow);
  background-color: white;
}

.loginOptions {
  display: flex;
  flex-direction: column;
}

.loginForm {
  display: flex;
  flex-direction: column;
}

.unionLink {
  margin-top: var(--large);
  width: 100%;
  text-align: center;
  background-color: #fafafa;
  padding: 1rem;
  box-sizing: border-box;
  border-bottom: 1px dashed var(--primary-color-dark);
}

.unionLink:hover {
  text-decoration: underline;
}

.loginButton {
  margin-top: var(--large);
}

.loginUnion {
  display: flex;
  flex-direction: column;
  margin-top: var(--small);
}

.loginUnion > label {
  padding-right: var(--medium);
}
