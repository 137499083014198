.salaryGraph {
  display: none;
  width: 100%;
  position: relative;
}

@media (min-width: 768px) {
  .salaryGraph {
    display: block;
  }
}

.salaryMarkerWrapper {
  margin-bottom: var(--small);
}

@media (min-width: 900px) {
  .salaryMarkerWrapper {
    position: absolute;
    top: -7px;
    right: 0;
    margin-bottom: 0;
  }
}
@media print {
  .salaryGraph {
    display: block;
    break-before: page;
    break-inside: avoid;
    margin-bottom: 2cm;
  }

  .salaryGraph :not(canvas) {
    display: none;
  }

  .salaryGraph canvas {
    width: calc(100% - 2em) !important;
    margin: 0 auto;
    height: auto !important;
    break-inside: avoid;
    padding-bottom: 4cm;
  }
}

