@font-face {
font-family: 'ProximaNova-Bold';
src: url('/preview/ProximaNova-Bold.woff') format('opentype');
}

@font-face {
    font-family: 'Passenger-Display';
    src: url('/preview/PassengerDisplay-Regular-BF63f2d24127b50.otf') format('opentype');
}

:root {
    --color-background: #383f82;
    --color-text: #000000;
}
  

.CareerspinContainer{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    z-index: 3;
}    

@media (min-width: 600px) {
    .CareerspinContainer{
        width: 622px;
    }   
} 
  

.CareerspinContainer h3 {
    font-size: 30px;
    float: left;
    margin: 0px 0px 2px 0px;
    font-family: 'ProximaNova-Bold', sans-serif;
    color:  var(--color-text);
    font-weight: 700;
}

.CareerspinContainer h2{
    font-family: 'ProximaNova-Bold', sans-serif;  
    font-size: 30px;
    margin: 10px 0px 2px 0px;
} 

.Input {
    text-align: center;
    font-size: 30px;
    font-family: 'ProximaNova-Bold', sans-serif;
    border: 1px solid white;
    margin: 15px 0px;
}

.transparentArrowBottom{
    display: block;
    height: 10px;
    margin-top: 0px;
    margin-bottom: 8px;
}
.transparentArrowBottom div{
    float: left;
    width: 50%;
    border-bottom: 20px solid #E6F4F6;;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 1;

}

.transparentArrowBottom div:first-child{
    border-right: 15px solid var(--color-background);

}

.transparentArrowBottom div:last-child{
    border-left: 15px solid var(--color-background);
    background-color: red !important;
}


.transparentArrow{
    display: block;
    height: 10px;
    margin-top: 2px;
    margin-bottom: 8px;
}
.transparentArrow div{
    float: left;
    width: 50%;
    border-bottom: 20px solid var(--color-background);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 1;
}

.transparentArrow div:first-child{
    border-right: 15px solid transparent;
    border-top-left-radius: 16px;
}

.transparentArrow div:last-child{
    border-left: 15px solid transparent;
    border-top-right-radius: 16px;
}
.link,
.spinContainer span,
.textContent p,
.textContent span,
.textContent Link,
.selectContainer span{
    font-family: 'ProximaNova-Bold', sans-serif;
}

.textContent span{
    font-weight:bold;
}

.spinContainer p {
    font-family: 'Passenger-Display', sans-serif;
    margin: 15px 0px 15px 0px;
}

.link{
    color:  #008091;
}

.selectContainer{
 margin: 0px 0px 15px 0px;
}
.selectContainer span{
    font-size: 16px;
}

.spinContainer{
    padding: 40px;
    background-color: var(--color-background);
}

.spinContainer span{
    font-size: 60px;
    color: var(--color-text);
 
}
.salary_module_container{
    display: flex;
    flex-direction: column;
}

.salary_module_container Slider{
color: red;
}

.spinContainer > div,
.salary_module_container{
    text-align: center;
}

.spinContainer p {
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    border-bottom-color: white;
    border-top-width: 1px;
    border-top-style: dashed;
    border-top-color: white;
    padding-top: 2px;
    color:  var(--color-text);
    text-align: center;
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.textContent {
    background-color: #E6F4F6;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding: 20px;
    margin-bottom: 30px;
}

.textContent h3{
    font-size: calc(1.25833rem + .09996vw);
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 700;
    line-height: 1.2;
    color: #C13D8C
}

.helpIcon a{
    cursor: pointer;
}

@media (min-width: 600px) {
    .spinContainer{
        min-height: 550px;
    }
  
}