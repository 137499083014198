@font-face {
    font-family: 'ProximaNova-Bold';
    src: url('/fonts/preview/ProximaNova-Semibold.woff') format('opentype');
}
@font-face {
    font-family: 'ProximaNova-Semibold';
    src: url('/fonts/preview/PassengerDisplay-Extrabold.otf') format('opentype');
}
.container{
    text-align: center;
    margin-top: 20px;
}
.active,
.customButton {
   
    height: 49px;
    letter-spacing: 0.5px;
    background-color:#dbdddd;
    color: var(--test);
    font-size: 17px;
    border: 1.5px solid white;
    cursor: pointer;
    font-family: 'ProximaNova-Bold',Arial,sans-serif;
    border:none;
    padding: 20px 20px 40px 20px;
    text-align: center;
    justify-content: center; /* Centrera horisontellt */
    align-items: center; /* Centrera vertikalt */
}
@media (min-width: 600px) {
.active,
.customButton{
    width: 120px;
}
}

  

.firstButton{
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
}

.lastButton{
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
}

.textcolorWhite{
    color: white
}
