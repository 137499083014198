@font-face {
  font-family: 'ProximaNova-SemiBold';
  src: url('/preview/ProximaNova-Semibold.woff') format('opentype');
}
@font-face {
  font-family: 'ProximaNova-Semibold';
  src: url('/preview/PassengerDisplay-Extrabold.otf') format('opentype');
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/preview/ProximaNova-Regular.woff') format('opentype');
}

@font-face {
  font-family: 'ProximaNova-Bold';
  src: url('/preview/ProximaNova-Bold.woff') format('opentype');
}

@media (max-width: 600px) {
  .parentHide{
    visibility: hidden;
  }
}

body {
  margin: 0;
  font-family: 'ProximaNova-SemiBold', Arial, sans-serif;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scaled-content {
  transform: scale(0.8); /* Ändra skalfaktorn för att justera skalningen */
  transform-origin: top left; /* Ange ursprungspunkten för skalningen */
}

.icon {
  width: 24px; /* Justera bredden efter behov */
  height: 24px; /* Justera höjden efter behov */
  margin-right: 10px; /* Justera höjden efter behov */
  margin-bottom: 5px;
}

.parentInputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.headerField {
  display: flex;
  align-items: center;
}

.inputField {
  display: flex;
  align-items: flex-end;
}

.inputField svg {
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  margin-right: 10px;
}

.inputField p {
  color: #007281;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.dropdowns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.dropdown {
  position: relative;
}

.dropdown svg {
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  margin-right: 10px;
}

.dropdown p {
  color: #007281;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.selectBox {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 0px !important;
}

.selectBox input {
  border-radius: 8px;
  height: 20px;
  margin-right: 20px;
}

.selectBox > div {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.selectBox label {
  color: var(--color-label);
  font-family: 'CustomFont', sans-serif;
  font-size: 18px;
  padding-bottom: 2px;
}

.activeIcon {
  position: relative;
  color: #008ea1;
  z-index: 1;
  pointer-events: none;
  font-style: bold;
  left: 85% !important;
  bottom: 40px;
  font-family: 'CustomFont', sans-serif;
}

.input {
  min-height: 50px;
  border: 0px;
  font-family: 'CustomFont', sans-serif;
  font-size: 18px;
}

.input-parent-salary {
  width: 100% !important;
  height: 48px;
  border-radius: 8px;
  border: 1px solid #c5c7c7;
  background: #fff;
  display: block !important;
}

.helpIcon a {
  width: 24px; /* Justera bredden efter behov */
  height: 24px; /* Justera höjden efter behov */
  margin-right: 10px; /* Justera höjden efter behov */
  cursor: pointer;
}

.helpChatBubble {
  visibility: hidden;
  width: 237px;
  text-align: start;
  border-radius: 8px;
  background: #fff;
  padding: 5px 15px;
  white-space: pre-wrap;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 2;
  left: -48px;
  transform: translateY(-50%) translateX(-100%);
  margin-right: 8px;
}

.helpChatBubbleContainer {
  position: relative;
}

.helpChatBubble::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 13px 15px 13px 0;
  border-color: transparent #fff transparent transparent;
  right: -15px;
  top: 49%;
  transform: translateY(-50%) rotateY(180deg);
}

.helpChatBubbleVisible {
  visibility: visible !important;
}

/* Calculation info modal*/

.calculationInfoModal {
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  scroll-behavior: unset; /* Återställ scroll-beteendet till standard */
}

.calculationInfoModalVisible {
  display: block;
}

.calculationInfoModalHidden {
  display: none;
}

.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 8px;
  width: 80%;
  position: fixed; /* Håller modalen fast i fönstret */
  top: 50%; /* Centrerar modalen vertikalt */
  left: 50%; /* Centrerar modalen horisontellt */
  transform: translate(
    -50%,
    -50%
  ); /* Centrerar modalen exakt mitt i fönstret */
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Calculation info chat bubble*/
.calculationInfoChatBubble {
  visibility: hidden;
  width: 300px;
  text-align: start;
  border-radius: 8px;
  background: #fff;
  padding: 5px 15px;
  white-space: pre-wrap;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 2;
  left: 17px;
  transform: translateY(-50%);
  margin-left: 8px;
}

.calculationInfoChatBubbleContainer {
  position: relative;
}

.calculationInfoChatBubble::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 13px 15px 13px 0;
  border-color: transparent #fff transparent transparent;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
}

.calculationInfoChatBubbleVisible {
  visibility: visible !important;
}

/*Maximize income chat bubble */

.maximizeIncomeChatBubble {
  width: 190px;
  height: 156px;
  border-radius: 8px;
  background: #fff;
  z-index: 9999;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  position: relative;
}

.snibb::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 15px 0 15px;
  border-color: #fff transparent transparent transparent;
  left: 50%;
  bottom: -15px;
  transform: translateX(-50%);
}

/* Snibb 1 */
.snibb1::before {
  content: '';
  position: absolute;
  width: 0px;
  height: 0;
  border-style: solid;
  border-width: 0 20px 20px 12px;
  border-color: transparent transparent white transparent;
  left: 9%;
  bottom: -20px;
  transform: scaleX(-1) rotate(180deg) translateX(-57%) skewX(43deg);
}

/* Snibb 2 */
.snibb2::before {
  content: '';
  position: absolute;
  width: 0px;
  height: 0;
  border-style: solid;
  border-width: 0 20px 20px 12px;
  border-color: transparent transparent white transparent;
  left: 75%;
  bottom: -20px;
  transform: rotate(180deg) translateX(-57%) skewX(43deg);
}

.hoverCursor {
  cursor: pointer;
}

.maximizeIncomeHeaderRectangle {
  width: 190px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 8px 8px 0px 0px;
  background: #058fa2;

  display: flex;
  justify-content: start; /* Centrera texten horisontellt */
  align-items: center; /* Centrera texten vertikalt */
}

.maximizeIncomeHeaderText {
  width: 190px;
  height: 19px;
  flex-shrink: 0;
  color: #fff;
  font-family: 'Proxima Nova';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left: 14px;
}

.maximizeIncomeContentRectangle1 {
  width: 190px;
  height: 53px;
}

.maximizeIncomeContentRectangle1Text {
  color: #c13d8c;
  font-family: 'Proxima Nova';
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left: 12px;
  padding-top: 12px;
  display: flex;
  justify-content: start; /* Centrera texten horisontellt */
}

.maximizeIncomeContentRectangle2 {
  width: 190px;
  height: 24px;
}

.maximizeIncomeContentRectangle2Text {
  color: #002b30;
  font-family: 'Proxima Nova';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 14px;
  display: flex;
  justify-content: start; /* Centrera texten horisontellt */
}

.maximizeIncomeContentRectangle3 {
  width: 190px;
  height: 32px;
}

.maximizeIncomeContentRectangle3Text1 {
  color: #002b30;
  font-family: 'Proxima Nova';
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left: 14px;
}

.maximizeIncomeContentRectangle3Text2 {
  color: #c13d8c;
  font-family: 'Proxima Nova';
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.divInomCanvas {
  display: none;
  position: absolute;
  top: 20px;
  left: 50px;
}

.divInomCanvas2 {
  display: none;
  position: absolute;
  top: 20px;
  left: 50px;
}

.divInomCanvas3 {
  display: flex; /* Använd flexbox */
  flex-direction: column; /* Ställ in flexriktningen till kolumn */
  align-items: center; /* Centrera elementen horisontellt */
  justify-content: center; /* Centrera elementen vertikalt */

  display: none;
  position: absolute;
  top: 20px;
  left: 50px;
  color: #c13d8c;
  text-align: center;
  font-family: 'Proxima Nova';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media (max-width: 769px) {
  .speechBubbleContent {
    display: none;
  }
  .header p {
    margin-left: 15px;
  }
  .chart > div {
    margin: 0px 12px 70px 12px;
    padding: 40px 2px 20px 2px;
  }

  .chartLabel {
    position: absolute;
    margin-left: 12px !important;
    top: 63px;
  }

  .selectBox {
    display: flex;
    flex-direction: column;
    /* margin: 0px 12px 0px 12px !important; */
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  .arrowleft {
    display: none;
  }

  .activeIconRight {
    left: 80% !important;
    color: green;
  }
  .activeIcon {
    left: 90%;
  }

  .chart {
    position: relative;
  }

  .chart > div {
    margin-top: 109px;
  }

  .result {
    width: 55%;
    height: 20px;
    top: -120px;
    left: 48%;
    background: transparent;
    color: white;
  }

  .result span {
    color: white;
  }
}

/* Grid calculation info*/
.grid-calculationinfomodal {
  display: grid;
  grid-template-columns: 1fr;
}

.grid-calculationinfomodal-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 4px;
}

.grid-calculationinfomodal-row-header {
  display: grid;
  grid-template-columns: 1fr;
  padding: 4px;
}

.grid-calculationinfomodal-row-decription {
  display: grid;
  grid-template-columns: 1fr;
  padding: 4px;
}


.flex-e-c {
  display: flex;
  justify-content: end; /* x */
  align-items: center; /* y */
}

.flex-s-c {
  display: flex;
  justify-content: start; /* x */
  align-items: center; /* y */
}

.p-10-0-10-0 {
  padding: 10px 0px 10px 0px !important;
}

.p-30-0-10-0 {
  padding: 30px 0px 10px 0px !important;
}

.p-40-0-0-100 {
  padding: 40px 0px 0px 100px !important;
}

.p-10-0-5-0 {
  padding: 10px 0px 5px 0px !important;
}

/* Grid */
.grid-parentalleave {
  display: grid;
  width: 630px;
  grid-template-rows: 60px 560px 1fr; /*Totala höjden på raderna måste få plats*/
  /* padding: 10px; */
}

@media(min-width: 600px) {
  .grid-parentalleave {
    width: 630px;
  }
}

.grid-parentalleave-r1 {
  background-color: #006371;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.grid-parentalleave-r2 {
  display: grid;
  background-color: #f7f7f7;
  padding-left: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  grid-template-rows:
    33px /*Rad2-Rad1-Mellanrum0*/
    0px /*Rad2-Rad2-I det här räkneverktyget*/
    26px /*Rad2-Rad3-Mellanrum1*/
    34px /*Rad2-Rad4-1. Vad har ni för månadslön före skatt?*/
    10px /*Rad2-Rad5-Mellanrum2*/
    30px /*Rad2-Rad6-Förälder 1*/
    8px /*Rad2-Rad7-Mellanrum3*/
    48px /*Rad2-Rad8-Textfält-32000*/
    54px /*Rad2-Rad9-Mellanrum4*/
    29px /*Rad2-Rad10-I hur många månader får ni föräldralön?*/
    14px /*Rad2-Rad11-Mellanrum5*/
    28px /*Rad2-Rad12-Förälder1*/
    8px /*Rad2-Rad13-Mellanrum6*/
    48px /*Rad2-Rad14-Textfält-10månader*/
    78px /*Rad2-Rad15-Mellanrum7*/
    65px /*Rad2-Rad16-Knapp*/
    0px; /*Rad2-Rad17-Mellanrum8*/
}


.grid-parentalleave-r2.no-radius {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.column {
}

.grid-parentalleave-r2-r1 {
  /*Rad2-Rad1-Mellanrum0*/
}

.grid-parentalleave-r2-r2 {
  /*Rad2-Rad2-I det här räkneverktyget*/
  width: 594px;
}

.grid-parentalleave-r2-r3 {
  /*Rad2-Rad3-Mellanrum1*/
}

.grid-parentalleave-r2-r4 {
  /*Rad2-Rad4-1. Vad har ni för månadslön före skatt?*/
}

.grid-parentalleave-r2-r5 {
  /*Rad2-Rad5-Mellanrum2*/
}

.grid-parentalleave-r2-r6 {
  /*Rad2-Rad6-Förälder 1*/

  display: grid;
  grid-template-columns: 255px 50px 280px 1fr;
}

.grid-parentalleave-r2-r7 {
  /*Rad2-Rad7-Mellanrum3*/
}

.grid-parentalleave-r2-r8 {
  /*Rad2-Rad8-Textfält-32000*/
  display: grid;
  grid-template-columns: 255px 50px 280px 1fr;
}

.grid-parentalleave-r2-r9 {
  /*Rad2-Rad9-Mellanrum4*/
}
.grid-parentalleave-r2-r10 {
  /*Rad2-Rad10-I hur många månader får ni föräldral?*/
}
.grid-parentalleave-r2-r11 {
  /*Rad2-Rad11-Mellanrum5*/
}
.grid-parentalleave-r2-r12 {
  /*Rad2-Rad12-Förälder1*/
  display: grid;
  grid-template-columns: 255px 50px 280px 1fr;
}
.grid-parentalleave-r2-r13 {
  /*Rad2-Rad13-Mellanrum6*/
}
.grid-parentalleave-r2-r14 {
  /*Rad2-Rad14-Textfält-10månader*/
  display: grid;
  grid-template-columns: 255px 50px 280px 1fr;
}
.grid-parentalleave-r2-r15 {
  /*Rad2-Rad15-Mellanrum7*/
}
.grid-parentalleave-r2-r16 {
  /*Rad2-Rad16-Knapp*/
  justify-self: center;
}

.grid-parentalleave-r2-r17 {
  /*Rad2-Rad17-Mellanrum8*/
}

.up {
  transform: rotate(180deg); /* eller den rotationsvinkel du föredrar */
}

.grid-parentalleave-r3 {
  padding-left: 20px;
  background-color: #F1F1F1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: grid;
  grid-template-rows:
    0px /*Rad3-Rad1-Mellanrum1*/
    29px /*Rad3-Rad2-Ni maximerar er inkomst med fördelningen 6 + 6 månader!*/
    0px /*Rad3-Rad3-Mellanrum1*/
    500px /*Rad3-Rad4-6+6 månader komponent*/
    80px /*Rad3-Rad5-Mellanrum2*/
    22px /*Rad3-Rad6-Testa och se hur andra fördelningar påverkar*/
    25px /*Rad3-Rad7-Mellanrum3*/
    116px /*Rad3-Rad8-Komponent med reglage*/
    29px /*Rad3-Rad9-Mellanrum4*/
    16px /*Rad3-Rad10-Så har vi räknat*/
    38px; /*Rad3-Rad11-Mellanrum5*/
}

.grid-parentalleave-r3-r1 {
  /*Rad3-Rad1-Mellanrum1*/
}

.grid-parentalleave-r3-r2 {
  /*Rad3-Rad2-Ni maximerar er inkomst */
  text-align: center;
}

.grid-parentalleave-r3-r3 {
  /*Rad3-Rad3-Mellanrum1*/
}

.grid-parentalleave-r3-r4 {
  /*Rad3-Rad4-6+6 månader komponent*/
}

.grid-parentalleave-r3-r5 {
  /*Rad3-Rad5-Mellanrum2*/
}

.grid-parentalleave-r3-r6 {
  /*Rad3-Rad6-Testa och se hur andra fördelningar */
  color: #058fa2;

  font-family: 'Proxima Nova';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.grid-parentalleave-r3-r7 {
  /*Rad3-Rad7-Mellanrum3*/
}

.grid-parentalleave-r3-r8 {
  /*Rad3-Rad8-Komponent med reglage*/
  display: grid;
  grid-template-rows: 30px 10px 30px 10px 36px; /* Fem rader */
}

.grid-parentalleave-r3-r9 {
  /*Rad3-Rad9-Mellanrum4*/
}
.grid-parentalleave-r3-r10 {
  /*Rad3-Rad10-Så har vi räknat*/
  display: flex;
  align-items: center;
  color: #000;
  font-family: 'Proxima Nova';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.grid-parentalleave-r3-r11 {
  /*Rad3-Rad11-Mellanrum5*/
}

.grid-parentalleave-r3-r8-r1 {
  /*Rad3-Rad8-Rad1-Reglage-Förälder1*/
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /*Tre kolumner*/
}

.grid-parentalleave-r3-r8-r1-c1 {
  justify-self: start;
  padding-left: 20px;
}

.grid-parentalleave-r3-r8-r1-c2 {
  justify-self: center;
}

.grid-parentalleave-r3-r8-r1-c3 {
  justify-self: end;
  padding-right: 56px;
}

.grid-parentalleave-r3-r8-r2 {
  /*Rad3-Rad8-Rad2-Reglage-6månader*/
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /*Tre kolumner*/
}

.grid-parentalleave-r3-r8-r2-c1 {
  justify-self: start;
  padding-left: 20px;
}

.grid-parentalleave-r3-r8-r2-c2 {
  justify-self: center;
}

.grid-parentalleave-r3-r8-r2-c3 {
  justify-self: end;
  padding-right: 56px;
}

.grid-parentalleave-r3-r8-r3 {
  /*Rad3-Rad8-Rad1-Reglage*/
  padding-right: 36px;
}

/* SELECT */
body .select-override {
  color: #002b30 !important;
}

.iconchevrondown-override {
  right: 40px !important;
}

.linkContainer {
  display: flex;
  align-items: center;
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.linkText {
  margin-left: -5px;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.maxIncomeText {
  font-family: 'Proxima Nova';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
text-align: center;

color: #C13D8C;
}

/* Knappar */
.turquoisebutton {
  width: 409px;
  height: 65px;
  border-radius: 8px;
  background-color: #005561;
  cursor: pointer;
  border: none;
}

.pinkbutton {
  width: 142px;
  height: 31px;
  border-radius: 8px;
  border: none;
  background: #c13d8c;
  color: #fff;
  text-align: center;
  font-family: 'Proxima Nova';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4px;
}
/* Textfält */
.textField {
  width: 549px;
  height: 85px;
  flex-shrink: 0;
  color: #002b30;
  font-size: 16px;
  line-height: 24px;
}

.text-15-teal {
  color: #002b30;
  font-family: 'Proxima Nova';
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text-16-teal {
  color: #002b30;
  font-family: 'Proxima Nova';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.text-20-teal {
  color: #002b30;
  font-family: 'Proxima Nova';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 120% */
  letter-spacing: 0.4px;
}

.text-16-white {
  color: #fff;
  font-family: 'Proxima Nova';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-18-white {
  color: #fff;
  font-family: 'Proxima Nova';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 111.111% */
  letter-spacing: 0.4px;
}

.text-20-white {
  color: #fff;
  text-align: center;
  font-family: 'Proxima Nova';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.text-24-white {
  color: #fff;
  font-family: 'Proxima Nova';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.text-16-black {
  color: #000;
  font-family: 'Proxima Nova';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-16-black-light {
  color: #000;
  font-family: 'Proxima Nova';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text-20-black-light {
  color: #000;
  font-family: 'Proxima Nova';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.text-16-teal-light {
  color: #058fa2;
  font-family: 'Proxima Nova';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text-16-teal-light-underline {
  color: #058fa2;
  font-family: 'Proxima Nova';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}

.text-20-teal-light {
  color: #058FA2;
  font-family: 'Proxima Nova';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.text-24-teal-light {
  color: #058fa2;
  font-family: 'Proxima Nova';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-16-pink {
  color: #c13d8c;
  text-align: center;
  font-family: 'Proxima Nova';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-28-pink {
  color: #c13d8c;
  text-align: center;
  font-family: 'Proxima Nova';
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

h2 {
  color: #058fa2;
  font-size: 24px;
  font-weight: 700;
}

/* Diverse */
.hidden {
  display: none;
}

.align-icon-to-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mr-10 {
  margin-right: 10px;
}

.mr-12 {
  margin-right: 12px;
}

.mt-16{
  margin-top: 16px;
}

/*Mobil*/
@media (max-width: 600px) {
  .grid-parentalleave-r1 {
    background-color: #006371;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .input-parent-salary {
    padding-left: 18px;
    padding-bottom: 6px;
}
  
  
  .turquoisebutton {
    width: 100%;
  }

  .mobileMain{
    padding: 15px 15px 0px 15px;
    background-color: #F7F7F7;
  }

  .mobileResultContent{
    background: #F1F1F1;
    margin-top: 40px;
    padding-top: 30px;
    margin-left: -15px;
    margin-right: -15px;
  }

  .mobileResult{
    padding-top: 30px;
    text-align: center;
  }

  .mobileContent p{
    font-family: 'Proxima Nova';
  }

  .mobiletable {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
  }
  
  .highlight-row{
    background-color: #c13d8c;
    color: white !important;
  }

  .incomeTable {
    border-collapse: collapse;
    width: 100%; /* Justera vid behov */
  }
  
  .incomeTable th, .incomeTable td {
    border-bottom: 1px solid #ddd;
    border-left: none;
    border-right: none;
    padding: 8px;
    border-top: none;
  }
  
  .incomeTable th {
    background-color: #f2f2f2;
    text-align: center;
  }
  
  .incomeTable td {
    text-align: center;
    font-size: 17px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .incomeTable thead tr {
    border-top: none;
  }

  .text-24-teal-light {
    font-size: 20px;
    font-family: 'ProximaNova-Bold' !important;
    display: flex;
  }

  .text-24-teal-light span{
    font-size: 20px;
    font-family: 'ProximaNova-Bold' !important;
  }
  .margin-bottom-20{
    margin-bottom: 20px;
    display: flex;
  }

  .text-28-pink {
    padding-top: 10px;
  }
  
  .text-20-teal-light {
    color: black;
    font-family: 'ProximaNova-SemiBold';
    font-size: 18px;
  }

  .text-20-teal {
    font-size: 18px;
  }

  .mobileicon {
      width: 24px; /* Justera bredden efter behov */
      height: 24px; /* Justera höjden efter behov */
      margin-right: 10px; /* Justera höjden efter behov */
      margin-bottom: 31px;  
  }

  .iconPil {
    margin-bottom: -2px;
  }

  .inputField {
    display: flex;
    align-items: flex-end;
    margin-bottom: 4px;
  }
  
  .grid-parentalleave-r3-r9{
    text-align: start !important;
  }
  .text-18-white {
    font-size: 18px;

  }
  /*Modal*/
  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Se till att modalen ligger över allt annat */
  }
  
  .modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
    min-width: 300px;
    z-index: 1001; /* Se till att innehållet i modalen ligger över överlägget */
    margin: 20px;
    bottom: 50px;
    
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    z-index: 1002; /* Se till att stängningsknappen ligger över allt annat */
  }
  
  .helpIcon {
    margin-bottom: -5 !important;
  }
  
  .hoverCursor {
    cursor: pointer;
  }

  .linkContainer {
    padding-top: 20px;
    padding-left: 5px;
  }

  .center{
    text-align:center;
  }

  .iconContinue {
    padding: 15px;
  }


}