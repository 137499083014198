.checkboxAdmin {
  margin: var(--xsmall) 0;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  display: inline-block;
}

.checkboxAdmin input[type='checkbox'] {
  height: 20px;
  width: 20px;
  pointer-events: none;
}

.checkboxAdmin .labelText {
  position: relative;
  display: inline-block;
  top: -4px;
}

.checkboxAdmin.disabled, .checkboxAdmin.disabled input {
  pointer-events: none;
}