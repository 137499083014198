.headerElement {
  position: relative;
  width: 100%;
  background-color: #fff;
}

.headerInner {
  margin: 0 auto;
  padding: var(--xxxlarge) 0;
  max-width: var(--outer-container-max-width);
  padding: var(--large) var(--medium);
}

.headerContent {
  max-width: var(--outer-container-max-width);
  margin: 0 auto;
  padding: var(--xxxlarge) 0 var(--large) 0;
}

.subMain {
  display: grid;
  grid-template-columns: auto 1fr;
  height: 100%;
}

.headerLogotype {
  position: relative;
}

.headerLogotype img {
  position: relative;
  max-width: 275px;
  max-height: 80px;
}

.bannerCurveBackground {
  background-color: var(--bg-grey);
}

.bannerDropshadow {
  filter: drop-shadow(1px 1px var(--border-color));
}

.bannerClippath {
  position: relative;
  width: 100%;
  height: 40px;
  background-color: #fff;
  -webkit-clip-path: ellipse(70% 46% at 50% 31%);
  clip-path: ellipse(70% 46% at 50% 31%);
}

@media (max-width: 768px) {
  .bannerClippath {
    -webkit-clip-path: ellipse(100% 46% at 50% 31%);
    clip-path: ellipse(100% 46% at 50% 31%);
  }

  .headerLogotype img {
    max-width: 200px;
  }
}

@media print {
  .bannerClippath {
    display: none;
  }

  .headerContent {
    padding-left: var(--medium);
    padding-bottom: 0;
  }
}
