.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin: auto;
  max-width: 700px;
  padding: 0 var(--medium);
}

.header {
  font-weight: bold;
  font-size: 42px;
  color: var(--teal);
  text-align: center;
  margin: 0;
}

.text {
  font-size: 22px;
  text-align: center;
}

.link {
  font-size: 22px;
  text-align: center;
  text-decoration: underline;
  margin-bottom: 25px;
}
