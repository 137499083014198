.selectBox {
  margin-bottom: var(--medium);
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchArea label {
  display: block;
  margin-bottom: var(--xsmall);
}

.searchInput {
  display: flex;
  flex-direction: column;
}

.missingFilterExplanations {
  padding: var(--medium) var(--medium) 0 var(--medium);
  box-sizing: border-box;
  border: var(--border-dashed-thick);
}

.missingFilterExplanations li {
  margin-left: var(--medium);
  margin-bottom: var(--medium);
}