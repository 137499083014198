.navigationItem {
  cursor: pointer;
  background-color: white;
  color: var(--dark-blue);
  border: none;
  padding: 12px 12px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  box-shadow: var(--box-shadow);
  border-bottom: 2px solid var(--dark-blue);
  outline: none;
  margin-bottom: var(--small);
  position: relative;
}

.navigationItem.isActive {
  background-color: var(--light-blue-bg);
  box-shadow: none;
  border-bottom: 2px solid var(--text-color);
  cursor: default;
  color: var(--text-color);
}

.navigationItem .activeIcon {
  position: absolute;
  top: 12px;
  right: 5px;
}

.isHidden {
  display: none;
}