.subSection {
  display: block;
  column-gap: var(--small);
  grid-template-columns: var(--xxlarge) 1fr;
  margin-top: var(--xxxxlarge);
  border: 0;
  margin: 0;
  padding: 0;
}

.subSectionTitle {
  margin-bottom: var(--large);
}

.subSectionContent {
  display: flex;
  flex-direction: column;
}

.subSectionContent label {
  font-size: var(--font-normal);
}

p {
  line-height: 1.5;
}

h2 {
  margin-top: var(--large);
}
