.buttonBar {
  border: var(--border);
  background-color: var(--bg-grey);
  padding: var(--small);
  border-radius: var(--border-radius-subtle);
  text-align: right;
}

.buttonBar.prevNext {
  text-align: center;
  height: 70px;
  box-sizing: border-box;
}

.buttonBar.hasMarginBottom {
  margin-bottom: var(--medium);
}

.buttonBar.prevNext button:first-child {
  float: left;
}

.buttonBar.prevNext button:last-child {
  float: right;
}

.buttonBar button {
  display: inline-block;
  margin-left: var(--medium);
}
