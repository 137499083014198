.inputReadOnly {
  margin-bottom: var(--large);
}

.inputReadOnly .inputLabel {
  font-weight: bold;
}

.inputReadOnly .textField {
  font-weight: normal;
  background-color: var(--bg-grey);
  border-bottom: var(--border-dashed-thick);
  padding: var(--small);
  margin-top: var(--small);
  word-break: break-all;
}

.inputReadOnly.useHyphens .textField {
  hyphens: manual;
  word-break: break-word;
}