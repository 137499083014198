/* The container */
.customCheckbox {
  background-color: var(--bg-grey);
  cursor: pointer;
  display: flex;
  margin-top: 2px;
  padding: var(--small);
  position: relative;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.customCheckbox:first-child {
  margin-top: 0;
}

.customCheckbox.loading,
.customCheckbox.disabled {
  pointer-events: none !important;
  opacity: 0.5;
}

.customCheckbox:hover {
  background-color: var(--bg-grey-darker);
}

/* Hide the browser's default checkbox */
.customCheckbox input {
  cursor: pointer;
  height: 22px;
  opacity: 0;
  position: absolute;
  width: 22px;
  border-radius: var(--border-radius-subtle);
}

/* Create a custom checkbox */
.customCheckbox .checkmarkWrapper {
  background-color: var(--checkbox-radio-bg);
  border: 1px solid var(--checkbox-radio-border);
  border-radius: var(--border-radius-subtle);
  box-sizing: border-box;
  left: var(--small);
  margin-top: -11px;
  position: absolute;
  top: 50%;
  height: 22px;
  width: 22px;
  overflow: hidden;
}

.customCheckbox input:focus ~ .checkmarkWrapper,
.customCheckbox input:focus-visible ~ .checkmarkWrapper {
  outline: 2px solid var(--primary-color-dark);
  outline-offset: 1px;
}

.customCheckbox input:focus:not(:focus-visible) {
  outline: none;
}

.customCheckbox .customCheckmark {
  border: 2px solid transparent;
  border-radius: var(--border-radius-subtle);
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
}

/* .customCheckbox:hover .customCheckmark {
  background-color: var(--checkbox-radio-bg-hover);
  border: 2px solid var(--checkbox-radio-bg);
} */

.customCheckbox:hover input:checked ~ .checkmarkWrapper .customCheckmark {
  background-color: transparent;
}

@media(max-width: 768px) {
  .customCheckbox:hover .customCheckmark {
    background-color: transparent;
  }
}

.customCheckbox.primary input:checked ~ .checkmarkWrapper,
.customCheckbox.primary:hover input:checked ~ .checkmarkWrapper {
  background-color: var(--primary-color);
}

.customCheckbox.primary input:checked ~ .checkmarkWrapper .customCheckmark {
  border-color: var(--primary-color);
}

.customCheckbox.secondary input:checked ~ .checkmarkWrapper,
.customCheckbox.secondary:hover input:checked ~ .checkmarkWrapper {
  background-color: var(--secondary-color);
}

.customCheckbox.secondary input:checked ~ .checkmarkWrapper .customCheckmark {
  border-color: var(--secondary-color);
}

.customCheckbox.primary input:checked ~ .checkmarkWrapper .customCheckmark:before,
.customCheckbox.secondary input:checked ~ .checkmarkWrapper .customCheckmark:before  {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: var(--border-radius-subtle);
  border: 2px solid transparent;
}

.customCheckbox.loading .checkmarkWrapper,
.customCheckbox.disabled .checkmarkWrapper {
  border-color: var(--checkbox-radio-border-disabled)
}

.customCheckbox.loading .customCheckmark,
.customCheckbox.disabled .customCheckmark {
  background-color: var(--checkbox-radio-bg-disabled);
  border-color: var(--checkbox-radio-bg-disabled);
}

.customCheckbox.loading .customCheckmark:before,
.customCheckbox.disabled .customCheckmark:before {
  border-color: transparent;
}

.customCheckbox.loading.primary input:checked ~ .checkmarkWrapper .customCheckmark,
.customCheckbox.disabled.primary input:checked ~ .checkmarkWrapper .customCheckmark,
.customCheckbox.loading.secondary input:checked ~ .checkmarkWrapper .customCheckmark,
.customCheckbox.disabled.secondary input:checked ~ .checkmarkWrapper .customCheckmark  {
  background-color: var(--checkbox-radio-bg-disabled);
  border-color: var(--checkbox-radio-bg-disabled);
}

.customCheckbox.loading.primary input:checked ~ .checkmarkWrapper .customCheckmark:before,
.customCheckbox.disabled.primary input:checked ~ .checkmarkWrapper .customCheckmark:before,
.customCheckbox.loading.secondary input:checked ~ .checkmarkWrapper .customCheckmark:before,
.customCheckbox.disabled.secondary input:checked ~ .checkmarkWrapper .customCheckmark:before {
  border: 0;
}

/* Create the checkmark/indicator (hidden when not checked) */
.customCheckbox .customCheckmark:after {
  content: '';
  display: none;
  position: absolute;
}

/* Show the checkmark when checked */
.customCheckbox input:checked ~ .checkmarkWrapper .customCheckmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.customCheckbox .customCheckmark:after {
  border: solid var(--text-color);
  border-width: 0 3px 3px 0;
  height: 10px;
  left: 5px;
  top: 0;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 4px;
}

.customCheckbox.loading .customCheckmark:after,
.customCheckbox.disabled .customCheckmark:after  {
  border-color: var(--checkbox-radio-border-disabled);
}

.customCheckbox .optionTextWrapper {
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.customCheckbox.primary .optionTextWrapper {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto;
}

.customCheckbox.secondary .optionTextWrapper {
  align-items: center;
  border-left: var(--border-dashed);
  flex-direction: row;
  margin-left: var(--xxlarge);
  padding-left: var(--small);
  position: relative;
  width: 100%;
}

.customCheckbox .optionName {
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}

.customCheckbox.primary .optionName {
  margin-left: var(--xxxlarge);
}

.customCheckbox.primary .filterCount {
  text-align: right;
}

.customCheckbox.secondary .filterCount {
  display: inline-block;
  font-size: 12px;
  font-style: italic;
  margin-top: 4px;
}

.customCheckbox .filterCount p {
  margin: 0;
  padding: 0;
}
