.userFilters {
  background-color: #ffffff;
  border: 1px solid var(--border-color);
  margin-bottom: var(--medium);
}

.filterHeading {
  display: grid;
  grid-template-columns: 1fr auto;
  box-sizing: border-box;
  padding: var(--small) var(--small) 16px var(--small);
}

.filterHeading h2 {
  margin: 0;
  font-size: 16px;
}

.filterHeading button {
  margin-bottom: -11px;
}

.filterHeading h2 svg {
  position: relative;
  top: 6px;
}

.indicatorSymbol {
  margin-right: var(--xsmall);
}

.resetFiltersBtn {
  margin-left: auto;
  margin-top: 2px;
  user-select: none;
  width: auto;
}

.disableMenuselection {
  background-color: lightgray;
}

@media print {
  .userFilters {
    display: none;
  }
}
