
.selectLabel {
  font-size: 13px;
  display: block;
  position: absolute;
  top: -11px;
  left: 5px;
  z-index: 10;
  background: white;
  box-sizing: border-box;
  padding: 2px 4px;
}

.selectLabel.isShaded {
  background: white;
  background: linear-gradient(to bottom, #f8f8f8, #fff);
}

.selectBox.hasMarginBottom {
  margin-bottom: var(--medium);
}

.selectWrapper {
  position: relative;
}

.selectBox.disabled .selectWrapper:after {
  border-top: 8px solid white;
}

.selectWrapper select {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  
  padding: 10px;
  width: 100%;
  margin: 0;


  box-sizing: border-box;
   
  height: 50px;
  color: #002B30;
  font-family: "Proxima Nova";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.4px;

  border-radius: 8px;
  border: 1px solid #C5C7C7;
  background: #FFF;
}

.selectWrapper Select option{
  height: 50px;
  color: #002B30;
  font-family: "Proxima Nova";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.selectBox.disabled {
  pointer-events: none;
}

.selectBox.disabled select {
  border-color: white;
}

.selectBox.isCompact .selectWrapper select {
  padding: 14px 30px 14px 15px;
}

.loaderWrapper {
  position: absolute;
  background-color: var(--bg-white-see-through);
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.loaderWrapper .selectLoader {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

@media(max-width: 600px) {
  .selectWrapper select {
    font-size: 18px;
    padding-left: 18px;
    padding-bottom: 6px;
    margin-bottom: 4px;
  }
}