.helpBox {
  background-color: aliceblue;
  border: var(--border-dashed-thick);
  padding: var(--large);
  border-radius: var(--border-radius);
  margin-top: var(--medium);
}

.helpBox h3 {
  margin-top: 0;
  background-color: aliceblue;
}
/* 
.helpBoxUl {
  list-style-type: decimal;
  padding: 0;
  margin: 0 0 0 var(--medium);
}

.helpBoxUl > li {
  font-weight: bold;
  margin-bottom: var(--xsmall);
}

.helpBoxUl ul {
  list-style-type: disc;
  padding: 0;
  margin: 0 0 var(--xlarge) var(--large);
}

.helpBoxUl ul:last-child {
  margin-bottom: 0;
}

.helpBoxUl ul li {
  margin-bottom: var(--xsmall);
} */
