.buttonTransparent {
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  text-align: start;
  width: 100%;
  color: var(--primary-color-dark);
}

.buttonTransparent:hover {
  background-color: var(--bg-grey-darker);
}

@media(max-width: 768px) {
  .buttonTransparent:hover {
    background-color: transparent;
  }
}

.buttonTransparent:focus:not(:focus-visible) {
  outline: none;
}

.buttonTransparent:disabled {
  color: var(--text-color-disabled);
  pointer-events: none;
}